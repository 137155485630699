import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { PageEvent } from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-viewsubcategory',
  templateUrl: './viewsubcategory.component.html',
  styleUrls: ['../styles.css']
})
export class ViewsubcategoryComponent implements OnInit {

  subcats :any;
  pageOptions = [5,10,15,20];
  totalSubcategories : any;
  subcategoriesPerPage = 5;
  currentpage = 1;
  holder ;
  constructor(private service: CategoryService, public snackBar: MatSnackBar, private router:Router, private actroute : ActivatedRoute) { }

  ngOnInit() {
    this.service
      .getSubCategories(this.subcategoriesPerPage, this.currentpage)
      .subscribe((res) => {
        this.subcats = res;
        console.log(this.subcats);
      });

    this.service.getSubCategoriesLength().subscribe((res) => {
      this.holder = res;
      this.totalSubcategories = this.holder.length;
    });
  }

  openSnackBar(message:any) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1000,
      data : message
    });
  }

  edit = (id:string) =>{
    this.router.navigate([`admin/update-product-subcategory/${id}`]);
  }

  refresh() {
    return this.service
      .getSubCategories(this.subcategoriesPerPage, this.currentpage)
      .subscribe((res) => {
        this.subcats = res;
      });
  }

  delete(id) {
    const cnf = confirm("Are you sure to delete this category?");
    if (cnf) {
      this.service.deleteSubcategories(id).subscribe((res) => {
        this.service.getCategoriesLength().subscribe((res) => {
          this.ngOnInit();
          this.openSnackBar('Subcategory Deleted...');
        });
      });
    } else {
      return;
    }
  }

  changePage(event: PageEvent) {
    this.subcategoriesPerPage = event.pageSize;
    this.currentpage = event.pageIndex + 1;
    this.service
      .getSubCategories(this.subcategoriesPerPage, this.currentpage)
      .subscribe((res) => {
        this.subcats = res;
      });
  }

}

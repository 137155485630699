import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { environment } from "../../../environments/environment";
import { ProductService } from 'src/app/services/product.service';
import { SnackbarComponent } from 'src/app/admin/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-popup',
  templateUrl: './productimagepopup.component.html',
  styleUrls: ['./productimagepopup.component.css']
})
export class ProductimagepopupComponent implements OnInit {

  images : any = [];
  bigImage : string = 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fen.m.wikipedia.org%2Fwiki%2FFile%3ANo_image_available.svg&psig=AOvVaw2sAqq1vYFRhKp10MQvWTF2&ust=1669363377162000&source=images&cd=vfe&ved=0CA8QjRxqFwoTCMC7hqqtxvsCFQAAAAAdAAAAABAE';
  currentImageId = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private ProductService:ProductService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    if(this.data.product.productImages != undefined){
      if(this.data.product.productImages.imagePath != undefined){
        this.images = this.data.product.productImages.imagePath
        this.bigImage = this.data.product.productImages.imagePath[this.currentImageId];
      }
    }
  }

  previousImage = () => {
    if(this.currentImageId > 0){
      this.currentImageId--;
      this.bigImage = this.data.product.productImages.imagePath[this.currentImageId];
    }
    console.log(this.currentImageId);
  }

  nextImage = () => {
    if(this.currentImageId !== this.images.length -1){
      this.currentImageId++;
      this.bigImage = this.data.product.productImages.imagePath[this.currentImageId];
    }
    console.log(this.currentImageId);
  }

  setImage = (image_id:any) =>{
    this.bigImage = this.data.product.productImages.imagePath[image_id];
    this.currentImageId = image_id;
  }

}
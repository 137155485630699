import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/app/services/jobs.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['../style.css']
})
export class JobsComponent implements OnInit {
  loading : boolean = false;
  jobdetails : any;
  id: any;
  title : any;
  details : any; 
  date : any;
  exprnc : any;
  constructor(private careerservice : JobsService , private actroute : ActivatedRoute ) { }

  ngOnInit() {
    this.actroute.paramMap.subscribe((params : ParamMap)=>{
      this.id = params.get('id');
  
      this.careerservice.getJobDetails(this.id).subscribe((res)=>{
        this.loading = true;
        this.jobdetails = res;
        this.title = this.jobdetails.title;
        this.details = this.jobdetails.job_details;
        this.exprnc = this.jobdetails.experience;
        this.date = this.jobdetails.posting_date;
      if(this.jobdetails){
        this.loading = false;
      }
      })
    })

  }

}

import { Component, OnInit } from '@angular/core';
import { PromotionService } from 'src/app/service/promotion.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-viewpromotion',
  templateUrl: './viewpromotion.component.html',
  styleUrls: ['../styles.css']
})
export class ViewpromotionComponent implements OnInit {
promotions: any;
  constructor(private promotionservice : PromotionService , private router : Router) { }

  ngOnInit() {
this.promotionservice.getPromotion().subscribe((res)=>{
  this.promotions = res;
console.log(this.promotions);
})
  }
  refresh(){
    return this.promotionservice.getPromotion().subscribe((res)=>{
      this.promotions  = res;
    })
  }
delete(id){
  const alert = confirm("Are you sure you want to delete this item?")
  if(alert ){
    this.promotionservice.deletePromotion(id).subscribe((res)=>{
    this.refresh()
    })
  }
  else{
    return;
  }
}
edit(id){
  this.router.navigate(['/admin/edit-promotion/', id]);
}
}

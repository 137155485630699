import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsandcondition',
  templateUrl: './termsandcondition.component.html',
  styleUrls: ['../style.css']
})
export class TermsandconditionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { AboutusService } from 'src/app/services/aboutus.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-add-corevalues',
  templateUrl: './add-corevalues.component.html',
  styleUrls: ['../styles.css']
})
export class AddCorevaluesComponent implements OnInit {

  public corevalues = {
    corevalues_title : '',
    corevalues_text : '',
    corevalues_image : File = null,
  };

  public core_image : any = "";
  constructor(private MatSnackBar:MatSnackBar, private AboutusService:AboutusService, private Router:Router) { }

  ngOnInit() {
  }

  selectImage(event : Event){
    const file = (event.target as HTMLInputElement).files[0];
    this.corevalues.corevalues_image =  (event.target as HTMLInputElement).files[0];
    let reader = new FileReader();
    reader.onload = () => {
      this.core_image = reader.result;
    }
    reader.readAsDataURL (file);
  }

  addCoreValue = () =>{
    const formData = new FormData();
    formData.append('corevalues_title', this.corevalues.corevalues_title);
    formData.append('corevalues_text', this.corevalues.corevalues_text);
    formData.append('corevalues_image', this.corevalues.corevalues_image);

    this.AboutusService.createCoreValues(formData).subscribe((data:any)=>{
      this.openSnackBar();
      this.Router.navigate(['/admin/view-core-values']);
    });
  }

  openSnackBar() {
    this.MatSnackBar.openFromComponent(SnackbarComponent, {
      duration: 500,
      data: 'Values Added Successfully'
    });
  }

}

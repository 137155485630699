import { Component, OnInit } from '@angular/core';
import { AboutusService } from 'src/app/services/aboutus.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['../style.css']
})
export class AboutusComponent implements OnInit {
  public response : any;
  public aboutUs : any;
  public corevalues : any;
  constructor(private container : AboutusService) { }

  ngOnInit() {
    this.container.getAbout().subscribe((res)=>{
      this.aboutUs = res;
    })
    this.getCoreValues();

  }

  getCoreValues = () =>{
    this.container.getAllCoreValues().subscribe((data:any)=>{
      this.response = data;
      this.corevalues = this.response;
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class CategoryService {
url :string = environment.apiUrl + 'category';
subcat_url :string = environment.apiUrl + 'subcategory';
  constructor(private http: HttpClient) { }
  
  getCategoriesLength(){
    return this.http.get(this.url);
  }
  
  getCategories(pageSize:number , currentPage:number){
    const queryParams = `?pageSize=${pageSize}&currentPage=${currentPage}`;
    return this.http.get(this.url + queryParams) ;
  }
  
  postCategory(data){
    return this.http.post(this.url , data)
  }

  updateCategory = (id, data) =>{
    return this.http.put(this.url + '/' +id, data);
  }

  updateSubcategory = (id, data) =>{
    return this.http.put(this.subcat_url + '/' +id, data);
  }
  
  deleteCategories(id){
    return this.http.delete(this.url+'/'+id);
  }
  
  getSingleCategory = (id:any) =>{
    return this.http.get(this.url+'/'+id);
  }

  // Subcategory Section
  
  getSubCategoriesLength(){
    return this.http.get(this.subcat_url);
  }
  
  getSubCategories(pageSize:number , currentPage:number){
    const queryParams = `?pageSize=${pageSize}&currentPage=${currentPage}`;
    return this.http.get(this.subcat_url + queryParams) ;
  }

  postSubcategory = (data:any) =>{
    return this.http.post(this.subcat_url , data);
  }

  deleteSubcategories(id){
    return this.http.delete(this.subcat_url+'/'+id);
  }
  
  getSingleSubcategory = (id:any) =>{
    return this.http.get(this.subcat_url+'/'+id);
  }

  getSingleSubcategoryByCategory = (categories:any) =>{
    return this.http.post(this.subcat_url+'/byCategory', categories);
  }

}

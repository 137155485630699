import { Component, OnInit } from '@angular/core';
import { AboutusService } from 'src/app/services/aboutus.service';
import { Router } from '@angular/router';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-corevalues',
  templateUrl: './corevalues.component.html',
  styleUrls: ['../styles.css']
})
export class CorevaluesComponent implements OnInit {
  public response : any;
  public corevalues : any;

  constructor(private MatSnackBar:MatSnackBar, private aboutservice : AboutusService, private Router : Router) { }

  ngOnInit() {
    this.getAllCoreValues();
  }

  getAllCoreValues = () =>{
    this.aboutservice.getAllCoreValues().subscribe((data:any)=>{
      this.response = data;
      this.corevalues = this.response;
    });
  }

  delete = (id:any) =>{
    let con = confirm('Are You Sure ?');
    if(con){
      this.aboutservice.deleteCoreValues(id).subscribe((data:any)=>{
        this.openSnackBar('Core Values Deleted...');
        this.getAllCoreValues();
      });
    }
  }

  openSnackBar(message:any) {
    this.MatSnackBar.openFromComponent(SnackbarComponent, {
      duration: 500,
      data: message
    });
  }

}

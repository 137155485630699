import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
import { SafeResourceUrl , DomSanitizer } from '@angular/platform-browser';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductimagepopupComponent } from '../productimagepopup/productimagepopup.component';
import { PopupComponent } from '../popup/popup.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import { BottomsheetComponent } from '../bottomsheet/bottomsheet.component';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['../style.css'],
  
})
export class ProductDetailsComponent implements OnInit {
  id: string;
  name : string;
  category : string;
  sizes: any;
  colors : string;
  shortdesc : string;
  details: any;
  images: any[];
  video: any;
  is_video : any;
  amazon :any;
 aliexress : string;
 alibaba: string;
 repoUrl:string;
  holder : any;
  selectedIndex :any = 0;
  status : boolean ;
  amazonuk : any;
  amazonus : any;
  amazonjapan : any;
  amazonfrance : any;
  amazonitaly : any;
  amazonspain : any;
  shopify : any;
  selectedcolor: any = 0;
  newImages : any;
  isColorClicked : boolean = false;
  holdImages:any;
  company:any =[];

  constructor(public dialog: MatDialog, private actroute : ActivatedRoute ,
     private productService : ProductService , private sanitizer: DomSanitizer, private bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.status = true;
      this.actroute.paramMap.subscribe((params : ParamMap)=>{
        this.id = params.get('id');
        // this.repoUrl="http://localhost:4200/product-details/" + this.id;
        this.productService.getProductDetails(this.id).subscribe((res)=>{
          this.holder = res;
          console.log(this.holder);
          this.name = this.holder.productname;
          this.category = this.holder.category.categoryname;
          this.sizes = this.holder.size.split(",");
          this.colors = this.holder.productImages.colors.filter((item, pos, self)=>{
            return self.indexOf(item) == pos;
          });
          this.shortdesc = this.holder.shortdescription;
          this.video = this.sanitizer.bypassSecurityTrustResourceUrl(this.holder.videopath);
          this.is_video = this.holder.videopath;
          this.holdImages = this.holder.productImages.imagePath;
          this.images = this.holdImages.filter((element, index)=>{
            return index < 5;
          });
          
          this.details = this.holder.productdetails;
          this.aliexress = this.holder.aliexpressLink;
          this.alibaba = this.holder.alibabaLink;
          this.shopify = this.holder.shopifyLink;
          this.amazon = this.holder.amazonLink;
          this.amazonus = this.amazon.amazonUs;
          this.amazonuk = this.amazon.amazonUk;
          this.amazonjapan = this.amazon.amazonJapan;
          this.amazonfrance = this.amazon.amazonFrance;
          this.amazonitaly = this.amazon.amazonItaly;
          this.amazonspain = this.amazon.amazonSpain;
          this.status =false;
        })
      });

      this.getCompanyDetails();

    }
    getId(id){
      this.selectedIndex = id
    }

    openBottomSheet() {
      this.bottomSheet.open(BottomsheetComponent, {
        data: [
          {
            link : {key : "Amazon US", value : this.amazonus}
          },
          {
            link : {key : "Amazon UK", value : this.amazonuk}
          },
          {
            link : {key : "Amazon Japan", value : this.amazonjapan},
          },
          {
            link : {key : "Amazon France", value : this.amazonfrance}
          },
          {
            link : {key : "Amazon Italy", value : this.amazonitaly}
          },
          {
            link : {key : "Amazon Spain", value : this.amazonspain}
          }
        ]
      });
    }
    
    getVdo(){
      this.selectedIndex = 'vdo';
    }

    getColor(col){
      var keys = [];
      this.newImages = [];
      this.holder.productImages.colors.forEach((e,i)=>{
      if(e === col && e != 4){
        keys.push(i);
      }
    });

    for(let i = 0 ; i< keys.length ; i++){
      if(i < 4){
        this.newImages.push(this.images[keys[i]])
      }
    }
    this.isColorClicked = true;
    }
    showall(){
      this.isColorClicked = false;
    }

    openPopup = () =>{
      this.openDialog(this.holder);
    }

    openDialog(product:any): void {
      const dialogRef = this.dialog.open(ProductimagepopupComponent, {
        width: '800px',
        data: {product :  product}
      });
    }

    getCompanyDetails = () =>{
      this.productService.getCompanyProfile().subscribe((data:any)=>{
        let response = data;
        if(response != ""){
          this.company = response[0];
        }
      });
    }

  }

import { Component, OnInit } from '@angular/core';
import { ImagesService } from 'src/app/services/images.service';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-viewimages',
  templateUrl: './viewimages.component.html',
  styleUrls: ['./viewimages.component.css']
})
export class ViewimagesComponent implements OnInit {
  imagesHolder : any;
  pageOptions = [1,2,5,10,15,20];
  totalProduct : any;
  prodcutPerPage = 2;
  currentpage = 1;
  holder ;
  constructor(private imagesService : ImagesService, private router: Router) { }

  
  ngOnInit() {
    this.imagesService.getImages(this.prodcutPerPage , this.currentpage).subscribe((res)=>{
      this.imagesHolder = res;
    })

  }
  refres(){
    return   this.imagesService.getImages(this.prodcutPerPage , this.currentpage).subscribe((res)=>{
      this.imagesHolder = res;
  
    })
  }
  delete(id:any){
    let cnfrm = confirm("Are you sure to delete this blog?");
    if(cnfrm){
      this.imagesService.deleteImages(id).subscribe((res)=>{
        this.ngOnInit();
      })
    }
  }
  changePage(event :PageEvent){
    this.prodcutPerPage = event.pageSize
    this.currentpage = event.pageIndex + 1
    this.imagesService.getImages(this.prodcutPerPage , this.currentpage).subscribe((res)=>{
      this.imagesHolder = res;
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PopupComponent } from '../popup/popup.component';
import { ProductimagepopupComponent } from '../productimagepopup/productimagepopup.component';


export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-allproducts',
  templateUrl: './allproducts.component.html',
  styleUrls: ['../style.css']
})
export class AllproductsComponent implements OnInit {
  animal: string = "Lion";
  name: string = "Alli";
  products:any;
  loading:any = false;
  show :boolean = false;
  listView : boolean = true;
  constructor(public dialog: MatDialog, private productService: ProductService , private router : Router) { }

  ngOnInit() {
    this.loading = true;
    this.productService.getLength().subscribe((res)=>{
      this.products = res;
      this.loading = false;
        if(this.products.length == 0)
        {
          this.show = true;
        }
    })
  }

  gotopro(product){
    this.router.navigate(['/product-deatils/', product._id]);
  }

  openDialog(product:any): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '400px',
      data: {product :  product}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }

  gridView = () =>{
    this.listView = !this.listView;
  }

  listingView = () =>{
    this.listView = !this.listView;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-viewvideo',
  templateUrl: './viewvideo.component.html',
  styleUrls: ['../styles.css']
})
export class ViewvideoComponent implements OnInit {
holder : any;
  constructor(private router: Router, private vdoser : VideoService) { }

  ngOnInit() {
this.vdoser.getVideos().subscribe((res)=>{
  this.holder = res;
})
  }
  refresh(){
    return this.vdoser.getVideos().subscribe((res)=>{
      this.holder = res;
    })
  }
  delete(id){
    const alert = confirm("Are you sure to delete this link?")
    if(alert){
      this.vdoser.deleteVideo(id).subscribe((res)=>{
      console.log(res);
      this.refresh();
      })
    }
  }
  edit(id){
    this.router.navigate(['/admin/edit-video/', id]);
    
  }
}

import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-updatecompany',
  templateUrl: './updatecompany.component.html',
  styleUrls: ['../styles.css']
})
export class UpdateCompanyComponent implements OnInit {
  public response : any;
  public company_details : any = [];
  public company_id : any;
  constructor(private ActivatedRoute:ActivatedRoute, private service: ProductService, private router: Router) { }

  ngOnInit() {
    this.company_id = this.ActivatedRoute.snapshot.paramMap.get('id');
    this.getCompanyDetails();
  }

  getCompanyDetails = () =>{
    this.service.getSingleCompanyProfile(this.company_id).subscribe((data:any)=>{
      this.response = data;
      this.company_details = this.response;
    });
  }

  submitCompanyProfile = () =>{
    this.service.updateCompanyProfile(this.company_id, this.company_details).subscribe((data:any)=>{
      this.response = data;
      this.company_details = this.response;
      this.router.navigate(['/admin/company']);
    });
  }
}

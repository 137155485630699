import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  ProductService
} from 'src/app/services/product.service';
import {
  CategoryService
} from 'src/app/services/category.service';
import {
  HttpClient
} from '@angular/common/http';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import {MatSnackBar} from '@angular/material';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-editproduct',
  templateUrl: './editproduct.component.html',
  styleUrls: ['../styles.css']
})
export class EditproductComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '330px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
    uploadUrl: 'https://soulaca.com/api/contentImages/',
    sanitize: true,
    toolbarPosition: 'top',
  };
  public imageDetails : any[] = [{
    image :'',
    color :''
      }];
  public Isfeatured: boolean | any;
  public buttonName: any;
  payload: any = [];
  prohold: any;
  images: any;
  className: string = 'btn btn-md btn-success';
  smallText: string = 'Your Product is not featured.';
  productTitle: string;
  productCat: any = [];
  productSubCat: any = [];
  productSize: string;
  selectedFile: any = [];
  productContents: string = '';
  cats: any;
  subcats:any = [];
  prodvideo: string;
  prodShort: string;
  features: string = '';
  flag: boolean = false;
  buyamazon: string;
  buyalibaba: string;
  buyaliexpress: string;
  urls = new Array < string > ();
  id: any;
  colors: any;
  imagepath: any;
  buyamazonus:string;
  buyamazonuk : string;
  buyamazonfrance : string;
  buyamazonspain : string;
  buyamazonitaly : string;
  buyamazonjapan: string;
  buyshopi: string;
  productFinder :any = {
    model_number : '',
    display_size : '',
    front_pane : '',
    resolution : '',
    dust_proof : '',
    wall_mount : '',
    visa_pattern : '',
    connections : '',
    speaker : '',
    operating_systems : '',
    storage : '',
    tv_power : '',
    remote_control_supplied : '',
    tv_dimesions : '',
    recess_wall_dimensions : '',
    parking_includes : '',
    special_features : '',
    tunner : '',
    mouse_pointer : '',
    voice_control : '',
    touch_keys : '',
    touch_screen : '',
    wifi : '',
    brightness : ''
  }


 public api: string = 'http://localhost:8081/api/products/';
  // public api: string = 'https://soulaca.com/api/products/';
  constructor(private route: Router, private http: HttpClient,
    private category: CategoryService, private actroute: ActivatedRoute, private service: ProductService, public snackBar: MatSnackBar) {}

  ngOnInit() {
    this.category.getCategoriesLength().subscribe((res) => {
      this.cats = res;
    })

    this.id = this.actroute.snapshot.paramMap.get('id');
    this.service.getProductDetails(this.id).subscribe((res) => {
      this.prohold = res;
       console.log(res);
      this.productTitle = this.prohold.productname;
      if(this.prohold.category != ""){
        this.productCat = this.prohold.category.map((element)=>{
          return element._id;
        });
      }

      if(this.prohold.subcategory != ""){
        this.productSubCat = this.prohold.subcategory.map((element)=>{
          return element._id;
        });
      }
      
      this.productSize = this.prohold.size;
      this.colors = this.prohold.productImages.colors;
      this.prodShort = this.prohold.shortdescription;
      this.features =  this.prohold.shortfeatures;
      this.urls = this.prohold.productImages.imagePath;
      console.log(this.urls);
      this.prodvideo = this.prohold.videopath;
      this.buyamazon = this.prohold.amazonLink;
      this.buyalibaba = this.prohold.alibabaLink;
      this.buyaliexpress = this.prohold.aliexpressLink;
      this.buyshopi = this.prohold.shopifyLink;
      this.Isfeatured = this.prohold.Isfeatured;
      this.buyamazonus = this.prohold.amazonLink.amazonUs;
      this.buyamazonuk = this.prohold.amazonLink.amazonUk ;
      this.buyamazonfrance = this.prohold.amazonLink.amazonFrance;
      this.buyamazonitaly = this.prohold.amazonLink.amazonItaly;
      this.buyamazonjapan = this.prohold.amazonLink.amazonJapan;
      this.buyamazonspain = this.prohold.amazonLink.amazonSpain;

      if(this.prohold.product_finder){
        this.productFinder  = {
          model_number : this.prohold.product_finder.model_number,
          display_size : this.prohold.product_finder.display_size,
          front_pane : this.prohold.product_finder.front_pane,
          resolution : this.prohold.product_finder.resolution,
          dust_proof : this.prohold.product_finder.dust_proof,
          wall_mount : this.prohold.product_finder.wall_mount,
          visa_pattern : this.prohold.product_finder.visa_pattern,
          connections : this.prohold.product_finder.connections,
          speaker : this.prohold.product_finder.speaker,
          operating_systems : this.prohold.product_finder.operating_systems,
          storage : this.prohold.product_finder.storage,
          tv_power : this.prohold.product_finder.tv_power,
          remote_control_supplied : this.prohold.product_finder.remote_control_supplied,
          tv_dimesions : this.prohold.product_finder.tv_dimesions,
          recess_wall_dimensions : this.prohold.product_finder.recess_wall_dimensions,
          parking_includes : this.prohold.product_finder.parking_includes,
          special_features : this.prohold.product_finder.special_features,
          tunner : this.prohold.product_finder.tunner,
          mouse_pointer : this.prohold.product_finder.mouse_pointer,
          voice_control : this.prohold.product_finder.voice_control,
          touch_keys : this.prohold.product_finder.touch_keys,
          touch_screen : this.prohold.product_finder.touch_screen,
          wifi : this.prohold.product_finder.wifi,
          brightness : this.prohold.product_finder.brightness
        }
      
      }

      if (this.Isfeatured) {
        this.buttonName = 'No';
        this.smallText = 'Your Product is featured.'
        this.className = 'btn btn-md btn-danger'
      } else {
        this.buttonName = "Yes"
        this.smallText = 'Your Product is not featured.'
        this.className = 'btn btn-md btn-success'
      }
      this.productContents = this.prohold.productdetails;
      // getting the subcategories
      if(this.prohold.category.length > 0){
        this.prohold.category.forEach(element_subca => {
          this.category.getSingleSubcategoryByCategory({categories : element_subca._id}).subscribe((data:any)=>{
            let response = data;
            if(response.length > 0){
              response.forEach(ele_subcat => {
                this.subcats.push(ele_subcat);
              });
            }
          })
        });
      }else{
        this.category.getSubCategories(1000, 1).subscribe((data:any)=>{
          console.log('test else');
          this.subcats = data;
        })
      }
    })

  }

  openSnackBar(message:any) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1000,
      data : message,
      panelClass: ['blue-snackbar']
    });
  }

  toogle() {
    this.Isfeatured = !this.Isfeatured
    if (this.Isfeatured) {
      this.buttonName = 'No';
      this.smallText = 'Your Product is featured.'
      this.className = 'btn btn-md btn-danger'
    } else {
      this.buttonName = "Yes"
      this.smallText = 'Your Product is not featured.'
      this.className = 'btn btn-md btn-success'
    }
  }

  getSubcategories = (event:any) =>{
    this.category.getSingleSubcategoryByCategory({categories : this.productCat}).subscribe((success:any)=>{
      this.subcats = success;
    });
  }

  removeExistingImage = (image_id:any) =>{
    let conf = confirm("Are You Sure ?");
    if(!conf){
      return;
    }
    this.urls = this.urls.filter((element:any, id:any)=>{
      return id !=image_id;
    });

    console.log(this.urls);
  }
  
  selectImage(event ){
    this.flag = false
    for (var i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);
      this.flag = true;
   }

}
  editProduct() {
    const data1 = new FormData();
    data1.append('id', this.id);
    data1.append('productname', this.productTitle);
    data1.append('category', this.productCat.toString());
    data1.append('subcategory', this.productSubCat.toString());
    data1.append('size', this.productSize || null);
   
    data1.append('shortdescription', this.prodShort);
    data1.append('shortfeatures', this.features);
    data1.append('amazonUs', this.buyamazonus || null);
    data1.append('amazonUk' , this.buyamazonuk || null);
    data1.append('amazonFrance', this.buyamazonfrance || null);
    data1.append('amazonSpain', this.buyamazonspain || null);
    data1.append('amazonItaly', this.buyamazonitaly || null);
    data1.append('amazonJapan', this.buyamazonjapan || null);
    data1.append('alibabaLink', this.buyalibaba || null);
    data1.append('aliexpressLink', this.buyaliexpress || null);
    data1.append('shopifyLink', this.buyshopi || null);
    if (!this.flag) {
      for (var i = 0; i < this.urls.length; i++) {
        data1.append("imagePath", this.urls[i]);
      }
    for (var i = 0 ; i< this.colors.length; i++){
      data1.append("colors", this.colors[i])
    }
    }
    if (this.flag) {
      for (var i = 0; i < this.selectedFile.length; i++) {  
        data1.append("image", this.selectedFile[i]);  
      }   
      for (var i = 0; i < this.imageDetails.length; i++) {  
     
       data1.append('colors',this.imageDetails[i].color );
      }
    }
    data1.append('videopath', this.prodvideo || null);
    data1.append('productdetails', this.productContents);
    data1.append('Isfeatured', this.Isfeatured);

    // Product Finder
    data1.append('model_number' , this.productFinder.model_number);
    data1.append('display_size' , this.productFinder.display_size);
    data1.append('front_pane' , this.productFinder.front_pane);
    data1.append('resolution' , this.productFinder.resolution);
    data1.append('dust_proof' , this.productFinder.dust_proof);
    data1.append('wall_mount' , this.productFinder.wall_mount);
    data1.append('visa_pattern' , this.productFinder.visa_pattern);
    data1.append('connections' , this.productFinder.connections);
    data1.append('speaker' , this.productFinder.speaker);
    data1.append('operating_systems' , this.productFinder.operating_systems);
    data1.append('storage' , this.productFinder.storage);
    data1.append('tv_power' , this.productFinder.tv_power);
    data1.append('remote_control_supplied' , this.productFinder.remote_control_supplied);
    data1.append('tv_dimesions' , this.productFinder.tv_dimesions);
    data1.append('recess_wall_dimensions' , this.productFinder.recess_wall_dimensions);
    data1.append('parking_includes' , this.productFinder.parking_includes);
    data1.append('special_features' , this.productFinder.special_features);
    data1.append('tunner' , this.productFinder.tunner);
    data1.append('mouse_pointer' , this.productFinder.mouse_pointer);
    data1.append('voice_control' , this.productFinder.voice_control);
    data1.append('touch_keys' , this.productFinder.touch_keys);
    data1.append('touch_screen' , this.productFinder.touch_screen);
    data1.append('wifi' , this.productFinder.wifi);
    data1.append('brightness' , this.productFinder.brightness);

    this.service.putProduct(this.id, data1).subscribe((res)=>{
      console.log(res)
      this.openSnackBar("Product Updated...");
      this.route.navigate(['/admin/view-products']);
    });
    // this.http.put(this.api + this.id, data1).subscribe((res) => {
    //   console.log(res);return;
    //   this.route.navigate(['/admin/view-products']);
    // })
  }
  addImages() {
    this.imageDetails.push({
     image:'',
     color:''
    
    });
    // console.log(this.imageDetails)
  }
  removeImages(i: number) {
    this.imageDetails.splice(i, 1);
    this.selectedFile.splice(i,1);
  }
}

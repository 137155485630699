import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oledinfo',
  templateUrl: './oledinfo.component.html',
  styleUrls: ['../style.css']
})
export class OledinfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

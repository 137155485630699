import { Component, OnInit,Inject } from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material';

@Component({
  selector: 'app-bottomsheet',
  templateUrl: './bottomsheet.component.html',
  styleUrls: ['./bottomsheet.component.css']
})
export class BottomsheetComponent implements OnInit {
  public links :any = [];
  public isLinksAvailable = false;
  
  constructor(private bottomSheetRef: MatBottomSheetRef<BottomsheetComponent>, 
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    this.links = this.data;
    
    this.links = this.links.filter((element)=>{
      if(element.link.value != 'null'){
        this.isLinksAvailable = true;
      }
      return element.link.value != 'null';
    });
  }

  openLink(event): void {
    this.dismissBottomSheet();
    window.open(event, '_blank');
  }

  dismissBottomSheet = () =>{
    this.bottomSheetRef.dismiss();
  }
}


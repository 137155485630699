import { Component, OnInit } from '@angular/core';
import { VideoService } from 'src/app/services/video.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addvideo',
  templateUrl: './addvideo.component.html',
  styleUrls: ['../styles.css']
})
export class AddvideoComponent implements OnInit {
  yvideo : string;
  constructor(private vdoSer : VideoService , private router : Router) { }

  ngOnInit() {
    
  }
addVideo(){
  let body ={
    videoLink : this.yvideo
  }
  this.vdoSer.postVideo(body).subscribe((res)=>{
    console.log(res);
    this.router.navigate(['/admin/view-video']);
  })
}
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PromotionService } from 'src/app/service/promotion.service';
import { ContentService } from 'src/app/service/content.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-promotiondetails',
  templateUrl: './promotiondetails.component.html',
  styleUrls: ['../style.css']
})
export class PromotiondetailsComponent implements OnInit {
id: any;
holder : any;
contents : any;
image : any;
title : any;
video: any;
yesImage : boolean;
yesVdo: boolean;
  constructor(private actroute : ActivatedRoute,  private sanitizer: DomSanitizer,
     private promotion: PromotionService ) { }

  ngOnInit() {
    this.id = this.actroute.snapshot.paramMap.get('id');
    this.promotion.getPromotionDetails(this.id).subscribe((res)=>{
      this.holder = res;
      this.title = this.holder.title;
      this.image =  this.holder.imagePath;
      this.contents =  this.holder.content;
      this.video = this.holder.videoPath;
      console.log ( this.image ,this.video)

      if(typeof(this.image == 'undefined')  ){
        this.yesVdo = true;
        this.yesImage = false;
      }
      else if(this.video == 'undefined'){
        this.yesImage = true;
        this.yesVdo = false;
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saleskit',
  templateUrl: './saleskit.component.html',
  styleUrls: ['./saleskit.component.css']
})
export class SaleskitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

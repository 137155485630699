import { Component, OnInit } from '@angular/core';
import { PromotionService } from 'src/app/service/promotion.service';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-addpromotion',
  templateUrl: './addpromotion.component.html',
  styleUrls: ['../styles.css']
})
export class AddpromotionComponent implements OnInit {
  ptitle: string ='';
  promotionImage: any;
  selectedFile: File = null;
  typeban: any;
  pvideo: string;
  showVideo: boolean;
  showImage: boolean;
  ccontent: string;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '330px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
    uploadUrl: 'https://soulaca.com/api/contentImages/',
    sanitize: true,
    toolbarPosition: 'top',
  
  };
  constructor(private promotionservice : PromotionService, private router : Router) {}


  ngOnInit() {

  }
  selectImage(event : Event){
    const file = (event.target as HTMLInputElement).files[0];
    this.selectedFile =  (event.target as HTMLInputElement).files[0];
    let reader = new FileReader();
    reader.onload = () => {
      this.promotionImage = reader.result;
    }
    reader.readAsDataURL (file);
  }
  addPromotion(){
    this.promotionservice.postPromotions(this.ptitle ,  this.selectedFile || null ,this.pvideo||null , this.ccontent).subscribe((res)=>{
      console.log(res);
      this.router.navigate(['/admin/view-promotion']);
    })
    }
    getvalue(sel){
    if(sel.typeban == "video"){
      this.showVideo =true;
      this.showImage = false
    }
    else if(sel.typeban == "image"){
      this.showImage = true;
      this.showVideo =false;
    }
    }
}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'merge'
})
export class MergePipe implements PipeTransform {

  transform(arr1, arr2): any {
    var arr = [];
    arr1.forEach((url, i) => {
      arr.push({ state: url , name: arr2[i] });
    });
  }

}

import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import * as jquery from 'jquery';

import { CategoryService } from 'src/app/services/category.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { JobsService } from 'src/app/services/jobs.service';
declare var google: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['../style.css']
})
export class NavbarComponent implements OnInit {
  productsearch: any;
  categories: any;
  jobs: any;
  year : any;
  lastScrollTop = 0
  currentYear : any;

  constructor(@Inject(DOCUMENT) private document: Document, private catServices: CategoryService,  private router : Router, private jobsservice : JobsService) {}

  ngOnInit() {
    this.currentYear = new Date().getFullYear()
    this.catServices.getCategoriesLength().subscribe((res)=>{
      this.categories = res;

      this.categories.forEach((data, index) => {
        let subcategories = [];
        this.catServices.getSingleSubcategoryByCategory({categories : [data._id]}).subscribe((data:any)=>{
          subcategories = data;
          this.categories[index]['subcategory'] = subcategories;
        });
      });
    })

    this.jobsservice.getJobsLength().subscribe((res)=>{
      this.jobs = res;
    })

    jQuery(document).ready(function () {
      jQuery('.navbar-light .dmenu').hover(function () {
        jQuery(this).find('.sm-menu').first().stop(true, true).slideDown(150);
      }, function () {
        jQuery(this).find('.sm-menu').first().stop(true, true).slideUp(105)
      });
    });

    var sp = document.querySelector('.search-open');
    var searchbar = document.querySelector('.search-inline');
    var shclose = document.querySelector('.search-close');

    function changeClass() {

      if(searchbar.classList.contains('search-visible')){
        searchbar.classList.remove('search-visible');
      }else{
        searchbar.classList.add('search-visible');
      }
    }

    function closesearch() {
      searchbar.classList.remove('search-visible');
    }
    sp.addEventListener('click', changeClass);
    shclose.addEventListener('click', closesearch);
    let date = new Date();
    this.year =  date.getFullYear();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    var searchbar = document.querySelector('.search-inline');
    searchbar.classList.remove('search-visible');

    if (document.body.scrollTop > 20 ||     
    document.documentElement.scrollTop > 20) {
      document.getElementById('header-bottom').classList.add('squizz');
    }else{
      document.getElementById('header-bottom').classList.remove('squizz');
    }
  }

  gotoPage(category){
   this.router.navigate(['/products/', category.categoryname.split(' ').join('-').toLowerCase(), category._id]) 
  }

  goto(){
    if (this.productsearch.length > 0){
      this.router.navigate(['/search-results', this.productsearch.toLowerCase()])
    }
   else{
     return;
   }
  }

  gotoSubCatPage = (subcategory:any) =>{
    this.router.navigate([`/products-subcategories/${subcategory.subcategoryname.split(' ').join('-').toLowerCase()}/${subcategory._id}`]);
  }

  viewJobs(id){
    this.router.navigate(['/job', id]);
  }
}


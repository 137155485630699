import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { Router, ActivatedRoute } from '@angular/router';
import {MatSnackBar} from '@angular/material';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-editcategory',
  templateUrl: './editcategory.component.html',
  styleUrls: ['../styles.css']
})
export class EditcategoryComponent implements OnInit {

  id :any;
  response : any;
  category : any = [];
  catname : any;
  constructor(private service: CategoryService, private router : Router, private actroute: ActivatedRoute, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.id = this.actroute.snapshot.paramMap.get('id');
    this.getCategoryById();
  }

  getCategoryById = () =>{
    this.service.getSingleCategory(this.id).subscribe((success:any)=>{
      this.response = success;
      this.category = this.response;
      this.catname = this.category.categoryname;
      console.log(this.category);
    });
  }

  openSnackBar(message:any) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1000,
      data : message
    });
  }

  updateCategory = () =>{
    const data ={
      categoryname : this.catname
    }

    this.service.updateCategory(this.id, data).subscribe((success:any)=>{
      this.openSnackBar("Category Updated...");
      this.router.navigate(['admin/view-product-categories']);
    });
  }

}

import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { environment } from "../../../environments/environment";
import { ProductService } from 'src/app/services/product.service';
import { SnackbarComponent } from 'src/app/admin/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  animal : any = 'Tiger';
  image : any = "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vectorstock.com%2Froyalty-free-vectors%2Fno-picture-vectors&psig=AOvVaw3qkFjFzf7x03auXNlz4cJH&ust=1669274304572000&source=images&cd=vfe&ved=0CA8QjRxqFwoTCPC1hcHhw_sCFQAAAAAdAAAAABAE";
  product_name : any = '';
  message : any = "";
  name : any = "";
  mobile : number = null;
  success : string = 'success';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private ProductService:ProductService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.product_name = this.data.product.productname;
    if(this.data.product.productImages != undefined){
      if(this.data.product.productImages.imagePath != undefined){
        this.image = this.data.product.productImages.imagePath[0];
      }
    }
  }

  onNoClick = () =>{
    console.log('test');
  }
  onSubmit = () =>{
    console.log(this.data);
  }

  openSnackBar() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 2000,
      data : "We have received your details, We will get back to you soon..."
    });
  }

  submitMessage = () =>{
    let product_url = `/${environment.site_url}/product-deatils/${this.data.product._id}`;
    const data = {
      product_url : product_url,
      name : this.name,
      mobile : this.mobile,
      message :this.message
    }
    this.ProductService.popUpMessageSend(data).subscribe((res)=>{
      if(res){
        this.openSnackBar();  
      }
    })
  }
}


import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-addsubcategory',
  templateUrl: './addsubcategory.component.html',
  styleUrls: ['../styles.css']
})
export class AddsubcategoryComponent implements OnInit {
  categories : any = [];
  response  :any = [];
  catid:string;
  catname:string;
  subcatname:string;
  constructor(private service: CategoryService, private router : Router, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.catid = null;
    this.getCategories();
  }

  getCategories = () =>{
    this.service.getCategories(1000, 1).subscribe((result:any)=>{
      this.response = result;
      this.categories = this.response;
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1000,
      data : "Subcategory Added..."
    });
  }

  addSubcategory(){
    this.service.getSingleCategory(this.catid).subscribe((success:any)=>{
      this.response = success;
      this.catname = this.response.categoryname;

      const data = {
        subcategoryname : this.subcatname,
        category : this.catid,
        categoryname :this.catname
      }
      
      this.service.postSubcategory(data).subscribe((res)=>{
        this.subcatname ='';
        this.openSnackBar();
        this.router.navigate(['/admin/view-product-subcategories']);
      })
    });
  }

}

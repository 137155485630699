import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-productsubcategory',
  templateUrl: './productsubcategory.component.html',
  styleUrls: ['../style.css']
})
export class ProductsubcategoryComponent implements OnInit {
  subcatgeory:any;
  products:any;
  headerimage : string = '../../../assets/images/all-product.jpg';
  loading : boolean = false;
  subCategoryName:string;
  newProducts:any =[];
  show : boolean = false;
  listView : boolean = true;
    constructor(public dialog: MatDialog, private productservice : ProductService , private router: Router, private actRoute : ActivatedRoute) { }
  

  ngOnInit() {
    this.actRoute.paramMap.subscribe((params : ParamMap)=>{
      this.newProducts = []
      this.show = false;
      this.loading = true;
      this.productservice.getProductBySubcategory(params.get('id')).subscribe((res:any)=>{
        this.products = res;
        this.loading = false;
        this.products.forEach((element, index) => {
          let subcategory = element.subcategory;
          subcategory.forEach((ele, ind) => {
            if(params.get('id') == ele){
              this.newProducts.push(element);
            }
          });
      });

      if(this.newProducts.length == 0){
          this.show = true;
      }
      else{ 
        this.show = false;
      }

      });
      this.actRoute.paramMap.subscribe((param: ParamMap)=>{
      this.subCategoryName = param.get('subcategoryname').split('-').join(' ');
      })
    })
  }

  gotopro(product){
    this.router.navigate(['/product-deatils/', product._id])
  }
  
  openDialog(product:any): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '400px',
      data: {product :  product}
    });
  }

  gridView = () =>{
    this.listView = !this.listView;
  }

  listingView = () =>{
    this.listView = !this.listView;
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class SignupService {
public url : string = environment.apiUrl + 'user/signup'
  constructor(private http: HttpClient) { }

  signup(data ){
    return this.http.post(this.url, data).subscribe((res)=>{
      console.log(res);
    },
    (err)=>{
      console.log(err);
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { ImagesService } from 'src/app/services/images.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-addimages',
  templateUrl: './addimages.component.html',
  styleUrls: ['./addimages.component.css']
})
export class AddimagesComponent implements OnInit {
  public images = {
    name : '',
    image : File = null,
  };
  public core_image:any='';
  constructor(private MatSnackBar:MatSnackBar, private ImagesService:ImagesService, private Router:Router) { }

  ngOnInit() {
  }
  
  addImage = () =>{
    console.log(this.images);
    this.ImagesService.postImages(this.images.name,this.images.image).subscribe((data:any)=>{
      this.openSnackBar();
      this.Router.navigate(['/admin/view-images']);
    });
  }
  selectImage(event : Event){
    const file = (event.target as HTMLInputElement).files[0];
    this.images.image =  (event.target as HTMLInputElement).files[0];
    let reader = new FileReader();
    reader.onload = () => {
      this.core_image = reader.result;
    }
    reader.readAsDataURL (file);
  }
  openSnackBar() {
    this.MatSnackBar.openFromComponent(SnackbarComponent, {
      duration: 500,
      data: 'New Images Added Successfully'
    });
  }

}

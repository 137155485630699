import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class ProductService {
public url : string = environment.apiUrl +'products';
public company_url : string = environment.apiUrl +'company';
  constructor(private http: HttpClient) { }
getProducts(pageSize:number , currentPage:number){
  const queryParams = `?pageSize=${pageSize}&currentPage=${currentPage}`;
  return this.http.get(this.url + queryParams);
}
getProductsBycategory(id){
  // const queryParams = `?categoryname=${categoryname}`;
  return this.http.get(this.url+'/category/'+ id);
}

productFinder = (param:any) =>{
  return this.http.post(this.url+'/product-finder', param);
}

search(productname:string){
  // const queryParams = `?categoryname=${categoryname}`;
  return this.http.get(this.url+'/search/'+ productname);
}
getProductDetails(id){
  return this.http.get(this.url +'/' +id);
}

getProductFinder = (product_id:any) =>{
  return this.http.post(this.url +'/get-productfinder', product_id);
}

getLength(){
  return this.http.get(this.url+'/');
}
postProduct(data : FormData){

  return this.http.post(this.url +'/' , data);
}
putProduct(id , data : FormData){

  return this.http.put(this.url +'/' +id, data);
}
deleteProduct(id){
  return this.http.delete(this.url +'/' +id);
}
getFeaturedProducts(){
  return this.http.get(this.url+'/featured/lists');
}
addawsimages(image){
  return this.http.post('http://localhost:8081/api/contentImages/' , image);
}

getProductBySubcategory = (id:any) =>{
  return this.http.get(this.url+'/subcategory/'+ id);
}

popUpMessageSend = (data) =>{
  return this.http.post(this.url +'/popup-message' , data);
}

getEnquiry = (pageSize:number , currentPage:number) =>{
  const queryParams = `?pageSize=${pageSize}&currentPage=${currentPage}`;
  return this.http.get(this.url +'/data/enquiry' + queryParams);
}

deleteEnquiry = (id:any) =>{
  return this.http.delete(this.url+`/enquiry/delete/${id}`);
}

getEnquiryLength = () =>{
  return this.http.get(this.url+'/data/enquiry');
}

getSuggestions = (data:any) =>{
  return this.http.get(this.url+`/suggestions?key=${data.key}&value=${data.value}`);
}

getCompanyProfile = () =>{
  return this.http.get(this.company_url+'/');
}

getSingleCompanyProfile = (id:any) =>{
  return this.http.get(this.company_url+'/' + id);
}

updateCompanyProfile = (id:any, data:any) =>{
  return this.http.put(this.company_url+'/update/' + id, data);
}

}

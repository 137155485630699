import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editteammember',
  templateUrl: './editteammember.component.html',
  styleUrls: ['./editteammember.component.css']
})
export class EditteammemberComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminloginComponent } from './admin/adminlogin/adminlogin.component';
import { AdminnavComponent } from './admin/adminnav/adminnav.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AddmemberComponent } from './admin/addmember/addmember.component';
import { ViewmembersComponent } from './admin/viewmembers/viewmembers.component';
import { AddaboutComponent } from './admin/addabout/addabout.component';
import { ViewaboutComponent } from './admin/viewabout/viewabout.component';
import { AddcategoryComponent } from './admin/addcategory/addcategory.component';
import { AddproductComponent } from './admin/addproduct/addproduct.component';
import { ViewproductsComponent } from './admin/viewproducts/viewproducts.component';
import { AddbannerComponent } from './admin/addbanner/addbanner.component';
import { ViewbannerComponent } from './admin/viewbanner/viewbanner.component';
import { SubscribersComponent } from './admin/subscribers/subscribers.component';
import { ContactqueriesComponent } from './admin/contactqueries/contactqueries.component';
import { AddblogComponent } from './admin/addblog/addblog.component';
import { ViewblogsComponent } from './admin/viewblogs/viewblogs.component';
import { EditteammemberComponent } from './admin/editteammember/editteammember.component';
import { EditaboutusComponent } from './admin/editaboutus/editaboutus.component';
import { EditproductComponent } from './admin/editproduct/editproduct.component';
import { EditblogComponent } from './admin/editblog/editblog.component';
import { AddtestimonialComponent } from './admin/addtestimonial/addtestimonial.component';
import { ViewtestimonialsComponent } from './admin/viewtestimonials/viewtestimonials.component';
import { EdittestimonialsComponent } from './admin/edittestimonials/edittestimonials.component';
import { AddjobComponent } from './admin/addjob/addjob.component';
import { ViewjobComponent } from './admin/viewjob/viewjob.component';
import { AppliedcandidatesComponent } from './admin/appliedcandidates/appliedcandidates.component';
import { EditjobComponent } from './admin/editjob/editjob.component';
import { EditbannerComponent } from './admin/editbanner/editbanner.component';
import { SignupComponent } from './admin/signup/signup.component';
import { AuthGuard } from './auth.guard';
import { NavbarComponent } from './user/navbar/navbar.component';
import { HomeComponent } from './user/home/home.component';
import { AboutusComponent } from './user/aboutus/aboutus.component';
import { ProductsComponent } from './user/products/products.component';
import { ContactusComponent } from './user/contactus/contactus.component';
import { SearchComponent } from './user/search/search.component';
import { ProductDetailsComponent } from './user/product-details/product-details.component';
import { CareerComponent } from './user/career/career.component';
import { BlogComponent } from './user/blog/blog.component';
import { BlogdetailsComponent } from './user/blogdetails/blogdetails.component';
import { ShowcommentsComponent } from './admin/showcomments/showcomments.component';
import { AllproductsComponent } from './user/allproducts/allproducts.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { TermsandconditionComponent } from './user/termsandcondition/termsandcondition.component';
import { PrivacystatementComponent } from './user/privacystatement/privacystatement.component';
import { JobsComponent } from './user/jobs/jobs.component';
import { LcminfoComponent } from './user/lcminfo/lcminfo.component';
import { OledinfoComponent } from './user/oledinfo/oledinfo.component';
import { DirectbondinginfoComponent } from './user/directbondinginfo/directbondinginfo.component';
import { CtpinfoComponent } from './user/ctpinfo/ctpinfo.component';
import { TftinfoComponent } from './user/tftinfo/tftinfo.component';
import { EpdinfoComponent } from './user/epdinfo/epdinfo.component';
import { AddpromotionComponent } from './admin/addpromotion/addpromotion.component';
import { ViewpromotionComponent } from './admin/viewpromotion/viewpromotion.component';
import { EditpromotionComponent } from './admin/editpromotion/editpromotion.component';
import { AddcontentComponent } from './admin/addcontent/addcontent.component';
import { ViewcontentComponent } from './admin/viewcontent/viewcontent.component';
import { EditcontentComponent } from './admin/editcontent/editcontent.component';
import { AddvideoComponent } from './admin/addvideo/addvideo.component';
import { ViewvideoComponent } from './admin/viewvideo/viewvideo.component';
import { EditvideoComponent } from './admin/editvideo/editvideo.component';
import { PromotiondetailsComponent } from './user/promotiondetails/promotiondetails.component';
import { FaqComponent } from './faq/faq.component';
import { WaterprooftvComponent } from './waterprooftv/waterprooftv.component';
import { IpratingsComponent } from './ipratings/ipratings.component';
import { AddsubcategoryComponent } from './admin/addsubcategory/addsubcategory.component';
import { ViewsubcategoryComponent } from './admin/viewsubcategory/viewsubcategory.component';
import { ViewcategoriesComponent } from './admin/viewcategories/viewcategories.component';
import { ProductsubcategoryComponent } from './user/productsubcategory/productsubcategory.component';
import { ProductfinderComponent } from './user/productfinder/productfinder.component';
import { EditcategoryComponent } from './admin/editcategory/editcategory.component';
import { EditsubcategoryComponent } from './admin/editsubcategory/editsubcategory.component';
import { EnquiryComponent } from './admin/enquiry/enquiry.component';
import { TroubleshootingComponent } from './troubleshooting/troubleshooting.component';
import { CorevaluesComponent } from './admin/corevalues/corevalues.component';
import { AddCorevaluesComponent } from './admin/add-corevalues/add-corevalues.component';
import { EditCorevaluesComponent } from './admin/edit-corevalues/edit-corevalues.component';
import { CompanyComponent } from './admin/company/company.component';
import { UpdateCompanyComponent } from './admin/updatecompany/updatecompany.component';
import {ViewimagesComponent} from './admin/viewimages/viewimages.component';
import {AddimagesComponent} from './admin/addimages/addimages.component';
const routes: Routes = [
  {path:'', component:NavbarComponent , children : [
    {path:'', component : HomeComponent},
    {path:'aboutus', component: AboutusComponent},
    {path:'blogs', component:BlogComponent},
    {path:'blogdetails/:id' ,component :BlogdetailsComponent},
    {path:'allproducts', component:AllproductsComponent},
    {path:'products/:categoryname/:id', component: ProductsComponent},
    {path:'products-subcategories/:subcategoryname/:id', component: ProductsubcategoryComponent},
    {path:'lcm', component: LcminfoComponent},
    {path:'oled',component:OledinfoComponent },
    {path:'direct-bonding', component : DirectbondinginfoComponent},
    {path:'ctp', component:CtpinfoComponent},
    {path:'tft', component:TftinfoComponent},
    {path:'epd', component:EpdinfoComponent},
    {path:'contact-us', component:ContactusComponent},
    {path:'career', component: CareerComponent},
    {path:'job/:id', component:JobsComponent},
    {path:'promotions/:id', component: PromotiondetailsComponent},
    {path:'search-results/:searchQuery', component:SearchComponent},
    {path:'product-deatils/:id', component:ProductDetailsComponent},
    {path:'termsandcondition' , component:TermsandconditionComponent},
    {path:'privacystatement' , component:PrivacystatementComponent},
    {path:'faq', component:FaqComponent},
    {path:'waterproof-tv', component: WaterprooftvComponent},
    {path:'ip-ratings', component: IpratingsComponent},
    {path:'product-finder', component: ProductfinderComponent},
    {path:'troubleshooting', component : TroubleshootingComponent}
  ]},
  {path:'adminlogin', component:AdminloginComponent},
  {path:'adminRegister', component: SignupComponent},
  // {path:'admin', component: AdminnavComponent, canActivateChild:[AuthGuard] , children:[
  {path:'admin', component: AdminnavComponent, children:[
    // {path:'admin', component: AdminnavComponent,canActivateChild:[AuthGuard], children:[
    {path:'', pathMatch:'full', redirectTo:'dashboard'},
    {path:'dashboard', component:DashboardComponent},
    {path:'add-team-member', component:AddmemberComponent},
    {path:'view-team-members', component:ViewmembersComponent},
    {path:'edit-team-member/:id', component:EditteammemberComponent},
    {path:'addjob', component:AddjobComponent},
    {path:'edit-job/:id', component:EditjobComponent},
    {path:'viewjobs',component: ViewjobComponent},
    {path:'appliedcandidates', component:AppliedcandidatesComponent},
    {path:'add-aboutus-content', component:AddaboutComponent},
    {path:'view-core-values', component:CorevaluesComponent},
    {path:'add-core-values', component:AddCorevaluesComponent},
    {path:'edit-core-values/:id', component:EditCorevaluesComponent},
    {path:'view-aboutus-contents', component:ViewaboutComponent},
    {path:'edit-about-us-contents/:id', component:EditaboutusComponent},
    {path:'add-product-category', component:AddcategoryComponent},
    {path:'add-product-subcategory', component : AddsubcategoryComponent},
    {path:'update-product-subcategory/:id', component : EditsubcategoryComponent},
    {path:'view-product-subcategories', component : ViewsubcategoryComponent},
    {path:'view-product-categories', component : ViewcategoriesComponent},
    {path:'add-product', component:AddproductComponent},
    {path:'view-products', component:ViewproductsComponent},
    {path:'edit-product/:id', component:EditproductComponent},
    {path:'add-video', component:AddvideoComponent},
    {path:'view-video', component:ViewvideoComponent},
    {path:'edit-video/:id', component:EditvideoComponent},
    {path:'add-promotion', component:AddpromotionComponent},
    {path:'view-promotion', component:ViewpromotionComponent},
    {path:'edit-promotion/:id', component:EditpromotionComponent},
    {path:'add-content', component:AddcontentComponent},
    {path:'view-content', component:ViewcontentComponent},
    {path:'edit-content/:id', component:EditcontentComponent},
    {path:'add-banner' , component:AddbannerComponent},
    {path:'view-banners', component:ViewbannerComponent},
    {path:'edit-banner/:id', component:EditbannerComponent},
    {path:'manage-subscribers', component:SubscribersComponent},
    {path:'contact-queries', component:ContactqueriesComponent},
    {path:'add-blog', component:AddblogComponent},
    {path:'view-blogs', component:ViewblogsComponent},
    {path:'edit-blog/:id', component:EditblogComponent},
    {path:'showComments/:id', component:ShowcommentsComponent},
    {path:'add-member', component:AddtestimonialComponent},
    {path:'view-members', component:ViewtestimonialsComponent},
    {path:'edit-member/:id', component:EdittestimonialsComponent},
    {path:'edit-category/:id', component : EditcategoryComponent},
    {path:'enquiry', component : EnquiryComponent},
    {path:'company', component : CompanyComponent},
    {path:'update-company/:id', component : UpdateCompanyComponent},
    {path:'view-images', component:ViewimagesComponent},
    {path:"add-images",component:AddimagesComponent}
]},
{path: '404', component: ErrorpageComponent},
{path: '**', redirectTo: '/404'}]

@NgModule({
  imports: [RouterModule.forRoot(routes , {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

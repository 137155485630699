import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from 'src/app/service/content.service';
import { PromotionService } from 'src/app/service/promotion.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-editcontent',
  templateUrl: './editcontent.component.html',
  styleUrls: ['../styles.css']
})
export class EditcontentComponent implements OnInit {
  promotions: any;
  cpromotion: any;
  ccontent: any;
  holder: any;
  id: any;
  promoid:string;
  hold: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '330px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
    uploadUrl: 'http://localhost:8081/api/contentImages/',
    sanitize: true,
    toolbarPosition: 'top',
  
  };
  constructor(private actroute : ActivatedRoute , private router : Router , private contentserv : ContentService , private promotionserv : PromotionService
    ) { }

  ngOnInit() {
this.promotionserv.getPromotion().subscribe((res)=>{
  this.promotions =  res;
});

this.id = this.actroute.snapshot.paramMap.get('id');
this.contentserv.getContentDetail(this.id).subscribe((res)=>{
this.holder = res;
this.promoid= this.holder.promotiontype;

this.ccontent = this.holder.content;
this.promotionserv.getPromotionDetails(this.promoid).subscribe((res)=>{
  this.hold = res;
    this.cpromotion = this.hold.title
    console.log(this.cpromotion)
  })
})
  }
  updateContent(){
    let data ={
      promotion : this.cpromotion,
      content : this.ccontent
    }
    this.contentserv.updateContent(this.id , data).subscribe((res)=>{
      console.log(res);
      this.router.navigate(['/admin/view-content']);
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { PageEvent } from '@angular/material/paginator';
import { Router } from "@angular/router";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['../styles.css']
})
export class CompanyComponent implements OnInit {
  enquiry :any;
  company :any;
  pageOptions = [5,10,15,20];
  totalProduct : any;
  prodcutPerPage = 5;
  currentpage = 1;
  holder;
  constructor(private service: ProductService, private Router:Router) { }

  ngOnInit() {
    this.service.getCompanyProfile().subscribe((res)=>{
      this.company = res;
    })
  }


  updateCompany = (id:any) =>{
    this.Router.navigate([`/admin/update-company/${id}`]);
  }

}

import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/service/content.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-viewcontent',
  templateUrl: './viewcontent.component.html',
  styleUrls: ['../styles.css']
})
export class ViewcontentComponent implements OnInit {
contents;
  constructor(private contserv : ContentService , private router : Router) { }

  ngOnInit() {
    this.contserv.getcontent().subscribe((res)=>{
      this.contents = res;
      console.log(this.contents);
    });
  }
  refresh(){
    this.contserv.getcontent().subscribe((res)=>{
      this.contents = res;
    });
  }
  delete(id){
    console.log(id);
    const confrm = confirm("Are you sure to delete this Content?");
    if(confrm){
      this.contserv.deleteContent(id).subscribe((res)=>{
       this.refresh();
        
      });
  }

  }
  edit(id){
    this.router.navigate(['/admin/edit-content/', id]);
  }
}

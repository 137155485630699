import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-viewproducts',
  templateUrl: './viewproducts.component.html',
  styleUrls: ['../styles.css']
})
export class ViewproductsComponent implements OnInit {
products:any;
images: Array<any> = [];
isFeatured : string = '';
pageOptions = [10, 30,50,100];
totalProduct : any;
prodcutPerPage = 10;
currentpage = 1;
holder ;
loading : boolean = true ;
categories: any;
notfoundText : boolean = false;
  constructor(private productser : ProductService, private router : Router , private catserv :  CategoryService) { }

  ngOnInit() {

    this.refresh();
    this.productser.getProducts(this.prodcutPerPage , this.currentpage).subscribe((res)=>{
    
      this.products = res;
      if(this.products)
      this.loading = false;
  this.catserv.getCategoriesLength().subscribe((res)=>{
    this.categories = res;
    
  })
    })
this.productser.getLength().subscribe((res)=>{
this.holder = res;
this.totalProduct = this.holder.length;

})
  }
  refresh(){
    return  this.productser.getProducts(this.prodcutPerPage , this.currentpage).subscribe((res)=>{
      this.products = res;
   
      console.log(this.products);
      this.totalProduct = this.products.length;
  
      
    })
  }
delete(id){
let confrm = confirm("Are you sure to delete this product ?")
if(confrm){
  this.productser.deleteProduct(id).subscribe((res)=>{
    this.ngOnInit();
  })
}
  }
  edit(id){
    this.router.navigate(['/admin/edit-product/' , id]);
  }
  searchProducts(val : string){
    
    if (val.length > 0){
      this.loading = true;
  this.productser.search(val.toLowerCase()).subscribe((res)=>{
this.products = res;
if(this.products.length == 0)
this.notfoundText = true;
else{
  this.notfoundText = false;   
}
if(this.products)
this.loading = false;
  })
    }
    else if(val.length == 0){
      this.ngOnInit();
      this.notfoundText = false;
    }
  }
  changePage(event :PageEvent){
    this.loading = true;
    this.prodcutPerPage = event.pageSize
    this.currentpage = event.pageIndex + 1
    this.productser.getProducts(this.prodcutPerPage , this.currentpage).subscribe((res)=>{
      this.products = res;
      if(this.products)
      this.loading = false;
  
    })
  }
  filter(event){
    let categoryname = event.value;
      this.productser.getProductsBycategory(categoryname.split('-').join(' ').toUpperCase()).subscribe((res)=>{
        this.loading = true;
        this.products = res;
        if(this.products){
          this.loading = false;
        }
      },
      (err)=>{
        console.log(err)
      })
    }
    clear(){
      this.ngOnInit();
      
    }
}

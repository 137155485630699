import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { ProductService } from 'src/app/services/product.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TypeaheadOptions } from 'ngx-bootstrap';
@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['../styles.css']
})
export class AddproductComponent implements OnInit {
  public Isfeatured:boolean | any = false;
  public buttonName:any = 'Yes';
  payload :any = [];
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '330px', 
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
    uploadUrl: 'https://soulaca.onrender.com/api/',
    sanitize: true,
    toolbarPosition: 'top',
  
  };
  images : any;
  public imageDetails : any[] = [{
    image :'',
    color :''
  }];
  className : string = 'btn btn-md btn-success';
  smallText : string = 'Your Product is not featured.';
  productTitle: string;
  productCat:any = [];
  productSubCat:any = [];
  productSize:string;
  productColors: string;
  selectedFile:any = [];
  productContents:string = '';
  cats:any;
  subcats:any;
  subcategories:any = [];
  features:string='';
  prodvideo:string;
  prodShort : string;
  isLoading : boolean = true ;
  buyamazonus:string;
  buyamazonuk : string;
  buyamazonfrance : string;
  buyamazonspain : string;
  buyamazonitaly : string;
  buyamazonjapan: string;
  buyalibaba:string;
  buyaliexpress:string;
  buyshopi : string;
  productcategory:any;
  url: any;

  productFinder :any = {
    model_number : '',
    display_size : '',
    front_pane : '',
    resolution : '',
    dust_proof : '',
    wall_mount : '',
    visa_pattern : '',
    connections : '',
    speaker : '',
    operating_systems : '',
    storage : '',
    tv_power : '',
    remote_control_supplied : '',
    tv_dimesions : '',
    recess_wall_dimensions : '',
    parking_includes : '',
    special_features : '',
    tunner : '',
    mouse_pointer : '',
    voice_control : '',
    touch_keys : '',
    touch_screen : '',
    wifi : '',
    brightness : ''
  }

  public dropdown : any = {
    model_number : false,
    model_number_suggestions : [],
    display_size : false,
    display_size_suggestions : [],
    front_pane : false,
    front_pane_suggestions : [],
    resolution : false,
    resolution_suggestions : [],
    dust_proof : false,
    wall_mount : false,
    visa_pattern : false,
    connections : false,
    speaker : false,
    operating_systems : false,
    storage : false,
    tv_power : false,
    remote_control_supplied : false,
    tv_dimesions : false,
    recess_wall_dimensions : false,
    parking_includes : false,
    special_features : false,
    tunner : false,
    mouse_pointer : false,
    voice_control : false,
    touch_keys : false,
    touch_screen : false,
    wifi : false,
    brightness : false
  };

  toppings :any = ["Mushroom"];
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  constructor(private router : Router , private category : CategoryService, private product : ProductService) { }

  ngOnInit() {
    this.category.getCategoriesLength().subscribe((res)=>{
      this.cats = res;
    })
  }
  selectImage(event ){

    for (var i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);
    
   }
   console.log(this.selectedFile);
}

getSubcategories = (event:any) =>{
  this.category.getSingleSubcategoryByCategory({categories : this.productCat}).subscribe((success:any)=>{
    this.subcats = success;
    console.log(this.subcats);
  });
}

showSuggestions = (key:any, event:any) =>{
  this.dropdown[key] = true;
  let dropdownkey = key + "_suggestions";
  this.dropdown[dropdownkey] = [];
  if(event.target.value == ""){
    this.dropdown[key] = false;
    return;
  }
  this.product.getSuggestions({key, value : event.target.value}).subscribe((data:any)=>{
    let response = data;
    response.forEach(element => {
      if(element.product_finder[key] != undefined){
        if(!this.dropdown[dropdownkey].includes(element.product_finder[key])){
          this.dropdown[dropdownkey].push(element.product_finder[key]);
        }
      }
    });
  });
  console.log(this.dropdown);
}

updateSuggestion = (suggestion:any, key) =>{
  this.productFinder[key] = suggestion;
  this.dropdown[key] = false;
}

  toogle(){
    this.Isfeatured =!this.Isfeatured
    if(this.Isfeatured){
      this.buttonName = 'No';
      this.smallText = 'Your Product is featured.'
      this.className ='btn btn-md btn-danger'
    }
    else{
      this.buttonName = "Yes" 
      this.smallText = 'Your Product is not featured.'
      this.className = 'btn btn-md btn-success'
    }
  }
  addProduct(){
    const data =  new FormData();
    this.isLoading = true;
    // this.productcategory = this.productCat.split(' ').join('-').toLowerCase();
    data.append('productname', this.productTitle);
    data.append('category', this.productCat.toString());
    data.append('subcategory', this.productSubCat.toString());
    data.append('size', this.productSize || null);
    data.append('shortdescription', this.prodShort);
    data.append('shortfeatures', this.features);
    data.append('amazonUs', this.buyamazonus || null);
    data.append('amazonUk' , this.buyamazonuk || null);
    data.append('amazonFrance', this.buyamazonfrance || null);
    data.append('amazonSpain', this.buyamazonspain || null);
    data.append('amazonItaly', this.buyamazonitaly || null);
    data.append('amazonJapan', this.buyamazonjapan || null);
    data.append('alibabaLink', this.buyalibaba || null);
    data.append('aliexpressLink', this.buyaliexpress || null);
    data.append('shopifyLink', this.buyshopi || null);
    for (var i = 0; i < this.selectedFile.length; i++) {  
      data.append("image", this.selectedFile[i]);  
    }   
    for (var i = 0; i < this.imageDetails.length; i++) {  
    data.append('colors',this.imageDetails[i].color );
    }
    data.append('videopath', this.prodvideo || null);
    data.append('productdetails', this.productContents);
    data.append('Isfeatured' , this.Isfeatured);

    // Product Finder
    data.append('model_number' , this.productFinder.model_number);
    data.append('display_size' , this.productFinder.display_size);
    data.append('front_pane' , this.productFinder.front_pane);
    data.append('resolution' , this.productFinder.resolution);
    data.append('dust_proof' , this.productFinder.dust_proof);
    data.append('wall_mount' , this.productFinder.wall_mount);
    data.append('visa_pattern' , this.productFinder.visa_pattern);
    data.append('connections' , this.productFinder.connections);
    data.append('speaker' , this.productFinder.speaker);
    data.append('operating_systems' , this.productFinder.operating_systems);
    data.append('storage' , this.productFinder.storage);
    data.append('tv_power' , this.productFinder.tv_power);
    data.append('remote_control_supplied' , this.productFinder.remote_control_supplied);
    data.append('tv_dimesions' , this.productFinder.tv_dimesions);
    data.append('recess_wall_dimensions' , this.productFinder.recess_wall_dimensions);
    data.append('parking_includes' , this.productFinder.parking_includes);
    data.append('special_features' , this.productFinder.special_features);
    data.append('tunner' , this.productFinder.tunner);
    data.append('mouse_pointer' , this.productFinder.mouse_pointer);
    data.append('voice_control' , this.productFinder.voice_control);
    data.append('touch_keys' , this.productFinder.touch_keys);
    data.append('touch_screen' , this.productFinder.touch_screen);
    data.append('wifi' , this.productFinder.wifi);
    data.append('brightness' , this.productFinder.brightness);

    this.product.postProduct(data).subscribe((res)=>{
      console.log(res);
      if(res)
      this.isLoading = false;
      this.router.navigate(['/admin/view-products']);
    }, err=>{
      alert('Internal Server Error, Please Contact Administration...');
    })
  }

  addImages() {
    this.imageDetails.push({
     image:'',
     color:''
    });
    console.log(this.imageDetails)
  }
  removeImages(i: number) {
    this.imageDetails.splice(i, 1);
    this.selectedFile.splice(i,1);
    console.log(this.selectedFile);
  }
}
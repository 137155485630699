import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ng2-ckeditor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminloginComponent } from './admin/adminlogin/adminlogin.component';
import { AdminnavComponent } from './admin/adminnav/adminnav.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AddmemberComponent } from './admin/addmember/addmember.component';
import { ViewmembersComponent } from './admin/viewmembers/viewmembers.component';
import { AddaboutComponent } from './admin/addabout/addabout.component';
import { ViewaboutComponent } from './admin/viewabout/viewabout.component';
import { AddcategoryComponent } from './admin/addcategory/addcategory.component';
import { ViewcategoriesComponent } from './admin/viewcategories/viewcategories.component';
import { AddproductComponent } from './admin/addproduct/addproduct.component';
import { ViewproductsComponent } from './admin/viewproducts/viewproducts.component';
import { AddblogComponent } from './admin/addblog/addblog.component';
import { ViewblogsComponent } from './admin/viewblogs/viewblogs.component';
import { ContactqueriesComponent } from './admin/contactqueries/contactqueries.component';
import { AddbannerComponent } from './admin/addbanner/addbanner.component';
import { ViewbannerComponent } from './admin/viewbanner/viewbanner.component';
import { SubscribersComponent } from './admin/subscribers/subscribers.component';
import { EditteammemberComponent } from './admin/editteammember/editteammember.component';
import { EditaboutusComponent } from './admin/editaboutus/editaboutus.component';
import { EditproductComponent } from './admin/editproduct/editproduct.component';
import { EditbannerComponent } from './admin/editbanner/editbanner.component';
import { EditblogComponent } from './admin/editblog/editblog.component';
import { AddtestimonialComponent } from './admin/addtestimonial/addtestimonial.component';
import { ViewtestimonialsComponent } from './admin/viewtestimonials/viewtestimonials.component';
import { EdittestimonialsComponent } from './admin/edittestimonials/edittestimonials.component';
import { AddjobComponent } from './admin/addjob/addjob.component';
import { ViewjobComponent } from './admin/viewjob/viewjob.component';
import { AppliedcandidatesComponent } from './admin/appliedcandidates/appliedcandidates.component';
import { AboutusService } from './services/aboutus.service';
import { CategoryService } from './services/category.service';
import { JobsService } from './services/jobs.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { EditjobComponent } from './admin/editjob/editjob.component';
import { BlogService } from './services/blog.service';
import { ReactiveFormsModule } from "@angular/forms";
import { BannerService } from './services/banner.service';
import { TeamService } from './services/team.service';
import { ProductService } from './services/product.service';
import { SpinnerComponent } from './spinner/spinner.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatPaginatorModule} from '@angular/material/paginator';
import { SignupComponent } from './admin/signup/signup.component';
import { SignupService } from './services/signup.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NavbarComponent } from './user/navbar/navbar.component';
import { HomeComponent } from './user/home/home.component';
import { AboutusComponent } from './user/aboutus/aboutus.component';
import { ProductsComponent } from './user/products/products.component';
import { ContactusComponent } from './user/contactus/contactus.component';
import { SearchComponent } from './user/search/search.component';
import { ProductDetailsComponent } from './user/product-details/product-details.component';
import { CareerComponent } from './user/career/career.component';
import { BlogComponent } from './user/blog/blog.component';
import { BlogdetailsComponent } from './user/blogdetails/blogdetails.component';
import { LoaderComponent } from './loader/loader.component';
import { ShowcommentsComponent } from './admin/showcomments/showcomments.component';
import { AllproductsComponent } from './user/allproducts/allproducts.component';
import { ShareModule } from '@ngx-share/core';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { TermsandconditionComponent } from './user/termsandcondition/termsandcondition.component';
import { PrivacystatementComponent } from './user/privacystatement/privacystatement.component';
import { SaleskitComponent } from './user/saleskit/saleskit.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { JobsComponent } from './user/jobs/jobs.component';
import { SafehtmlPipe } from './safehtml.pipe';
import { LcminfoComponent } from './user/lcminfo/lcminfo.component';
import { OledinfoComponent } from './user/oledinfo/oledinfo.component';
import { TftinfoComponent } from './user/tftinfo/tftinfo.component';
import { UserComponent } from './user/user.component';
import { EpdinfoComponent } from './user/epdinfo/epdinfo.component';
import { DirectbondinginfoComponent } from './user/directbondinginfo/directbondinginfo.component';
import { CtpinfoComponent } from './user/ctpinfo/ctpinfo.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AddpromotionComponent } from './admin/addpromotion/addpromotion.component';
import { ViewpromotionComponent } from './admin/viewpromotion/viewpromotion.component';
import { EditpromotionComponent } from './admin/editpromotion/editpromotion.component';
import { AddcontentComponent } from './admin/addcontent/addcontent.component';
import { ViewcontentComponent } from './admin/viewcontent/viewcontent.component';
import { EditcontentComponent } from './admin/editcontent/editcontent.component';
import { AddvideoComponent } from './admin/addvideo/addvideo.component';
import { ViewComponent } from './admin/view/view.component';
import { ViewvideoComponent } from './admin/viewvideo/viewvideo.component';
import { EditvideoComponent } from './admin/editvideo/editvideo.component';
import { MergePipe } from './merge.pipe';
import {MatChipsModule} from '@angular/material/chips';
import { ContactComponent } from './user/contact/contact.component';
import { PromotiondetailsComponent } from './user/promotiondetails/promotiondetails.component';
import { SafePipe } from './safe.pipe';
import { FaqComponent } from './faq/faq.component';
import { WaterprooftvComponent } from './waterprooftv/waterprooftv.component';
import { IpratingsComponent } from './ipratings/ipratings.component';
import { AddsubcategoryComponent } from './admin/addsubcategory/addsubcategory.component';
import { ViewsubcategoryComponent } from './admin/viewsubcategory/viewsubcategory.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { SnackbarComponent } from './admin/snackbar/snackbar.component';
import { ProductsubcategoryComponent } from './user/productsubcategory/productsubcategory.component';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { ProductfinderComponent } from './user/productfinder/productfinder.component';
import { EditcategoryComponent } from './admin/editcategory/editcategory.component';
import { EditsubcategoryComponent } from './admin/editsubcategory/editsubcategory.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { MatDialogModule } from '@angular/material/dialog';
import { PopupComponent } from './user/popup/popup.component';
import { BottomsheetComponent } from "./user/bottomsheet/bottomsheet.component"
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { EnquiryComponent } from './admin/enquiry/enquiry.component';
import { CompanyComponent } from './admin/company/company.component';
import { UpdateCompanyComponent } from './admin/updatecompany/updatecompany.component';
import { ProductimagepopupComponent } from './user/productimagepopup/productimagepopup.component';
import { TroubleshootingComponent } from './troubleshooting/troubleshooting.component';
import { CorevaluesComponent } from './admin/corevalues/corevalues.component';
import { EditCorevaluesComponent } from './admin/edit-corevalues/edit-corevalues.component';
import { AddCorevaluesComponent } from './admin/add-corevalues/add-corevalues.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import {MatListModule} from '@angular/material/list';
import { ViewimagesComponent } from './admin/viewimages/viewimages.component';
import { AddimagesComponent } from './admin/addimages/addimages.component';


@NgModule({
  declarations: [
    AppComponent,
    SafehtmlPipe,
    AdminloginComponent,
    AdminnavComponent,
    DashboardComponent,
    AddmemberComponent,
    ViewmembersComponent,
    AddaboutComponent,
    ViewaboutComponent,
    AddcategoryComponent,
    ViewcategoriesComponent,
    AddproductComponent,
    ViewproductsComponent,
    AddblogComponent,
    ViewblogsComponent,
    ContactqueriesComponent,
    AddbannerComponent,
    ViewbannerComponent,
    SubscribersComponent,
    EditteammemberComponent,
    EditaboutusComponent,
    EditproductComponent,
    EditbannerComponent,
    EditblogComponent,
    AddtestimonialComponent,
    ViewtestimonialsComponent,
    EdittestimonialsComponent,
    AddjobComponent,
    ViewjobComponent,
    AppliedcandidatesComponent,
    EditjobComponent,
    SpinnerComponent,
    SignupComponent,
    NavbarComponent,
    HomeComponent,
    AboutusComponent,
    ProductsComponent,
    ContactusComponent,
    SearchComponent,
    ProductDetailsComponent,
    CareerComponent,
    BlogComponent,
    BlogdetailsComponent,
    LoaderComponent,
    ShowcommentsComponent,
    AllproductsComponent,
    ErrorpageComponent,
    TermsandconditionComponent,
    PrivacystatementComponent,
    SaleskitComponent,
    JobsComponent,
    SafehtmlPipe,
    LcminfoComponent,
    OledinfoComponent,
    TftinfoComponent,
    UserComponent,
    EpdinfoComponent,
    DirectbondinginfoComponent,
    CtpinfoComponent,
    AddpromotionComponent,
    ViewpromotionComponent,
    EditpromotionComponent,
    AddcontentComponent,
    ViewcontentComponent,
    EditcontentComponent,
    AddvideoComponent,
    ViewComponent,
    ViewvideoComponent,
    EditvideoComponent,
    MergePipe,
    ContactComponent,
    PromotiondetailsComponent,
    SafePipe,
    FaqComponent,
    WaterprooftvComponent,
    IpratingsComponent,
    AddsubcategoryComponent,
    ViewsubcategoryComponent,
    SnackbarComponent,
    ProductsubcategoryComponent,
    ProductfinderComponent,
    EditcategoryComponent,
    EditsubcategoryComponent,
    PopupComponent,
    BottomsheetComponent,
    EnquiryComponent,
    CompanyComponent,
    ProductimagepopupComponent,
    TroubleshootingComponent,
    CorevaluesComponent,
    EditCorevaluesComponent,
    AddCorevaluesComponent,
    UpdateCompanyComponent,
    ViewimagesComponent,
    AddimagesComponent
  ],
  imports: [
    BrowserModule,
    ShareModule,
    AppRoutingModule,
    CKEditorModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    SwiperModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    AngularEditorModule,
    MatChipsModule,
    MatSnackBarModule,
    IvyCarouselModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatBottomSheetModule,
    MatListModule
  ],
  exports : [
    PopupComponent,
    BottomsheetComponent,
    ProductimagepopupComponent
  ],
  entryComponents: [SnackbarComponent, PopupComponent, BottomsheetComponent, ProductimagepopupComponent],
  providers: [AboutusService, CategoryService, JobsService, BlogService, BannerService, 
    TeamService, ProductService, SignupService, {provide : LocationStrategy , useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }

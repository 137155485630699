import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { Router, ActivatedRoute } from '@angular/router';
import {MatSnackBar} from '@angular/material';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-editsubcategory', 
  templateUrl: './editsubcategory.component.html',
  styleUrls: ['../styles.css']
})
export class EditsubcategoryComponent implements OnInit {
  id :any;
  response : any;
  subcategory : any = [];
  catname:string;
  subcatname:string;
  categories : any = [];
  catid:string;

  constructor(private service: CategoryService, private router : Router, private actroute: ActivatedRoute, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.id = this.actroute.snapshot.paramMap.get('id');
    this.getCategories();
    this.getSubCategoryById();
  }

  getCategories = () =>{
    this.service.getCategories(1000, 1).subscribe((result:any)=>{
      this.response = result;
      this.categories = this.response;
    });
  }

  getSubCategoryById = () =>{
    this.service.getSingleSubcategory(this.id).subscribe((success:any)=>{
      this.response = success;
      this.subcategory = this.response;
      this.subcatname = this.subcategory.subcategoryname;
      this.catid = this.subcategory.category
    });
  }

  openSnackBar(message:any) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1000,
      data : message
    });
  }

  updateSubcategory(){
    this.service.getSingleCategory(this.catid).subscribe((success:any)=>{
      this.response = success;
      this.catname = this.response.categoryname;

      const data = {
        subcategoryname : this.subcatname,
        category : this.catid,
        categoryname :this.catname
      }
      
      this.service.updateSubcategory(this.id, data).subscribe((res)=>{
        this.subcatname ='';
        this.openSnackBar("Subcategory Updated...");
        this.router.navigate(['/admin/view-product-subcategories']);
      })
    });
  }

}

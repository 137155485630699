import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-editvideo',
  templateUrl: './editvideo.component.html',
  styleUrls: ['../styles.css']
})
export class EditvideoComponent implements OnInit {
id: any;
yvideo : string;
holder : any;
  constructor(private router:Router, private actroute : ActivatedRoute, private vdoserv : VideoService) { }

  ngOnInit() {
this.id = this.actroute.snapshot.paramMap.get('id');
this.vdoserv.getVideoDetail(this.id).subscribe((res)=>{
  this.holder = res;
  console.log(this.holder);
  this.yvideo = this.holder.videoLink
})
  }
  editVideo(){
    let data ={
      videoLink : this.yvideo
    }
    this.vdoserv.updateVideo(this.id , data).subscribe((res)=>{
      console.log(res);
      this.router.navigate(['/admin/view-video']);
    })
  }
}

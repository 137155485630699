import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AboutusService {
public url : string = environment.apiUrl + 'aboutus';
public url_corevalues : string = environment.apiUrl + 'corevalues';
  constructor(private http: HttpClient) { }
  getAbout(){
    return this.http.get(this.url);
  }
  getAboutDetail(id){
    return this.http.get(this.url+'/'+id);
  }
  postAbout(data){
  return this.http.post(this.url , data);
  }
  updateAbout(id, data){
    return this.http.put(this.url + '/' + id, data);
  }
  deleteAbout(id){
    return this.http.delete(this.url+'/'+ id);
  }

  getAllCoreValues = () =>{
    return this.http.get(this.url_corevalues);
  }

  createCoreValues = (data:any) =>{
    return this.http.post(this.url_corevalues, data);
  }

  getSingleCoreValue = (id:any) =>{
    return this.http.get(this.url_corevalues+'/'+id);
  }

  updateCoreVlues = (id:any, core_data:any) =>{
    return this.http.put(this.url_corevalues + '/' + id, core_data);
  }

  deleteCoreValues = (id:any) =>{
    return this.http.delete(this.url_corevalues+'/'+ id);
  }

}

import { Component, OnInit } from '@angular/core';
import { AboutusService } from 'src/app/services/aboutus.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-edit-corevalues',
  templateUrl: './edit-corevalues.component.html',
  styleUrls: ['../styles.css']
})
export class EditCorevaluesComponent implements OnInit {
  public corevalues = {
    corevalues_title : '',
    corevalues_text : '',
    corevalues_image : '',
  };

  public core_image : any = "";
  public coreId :any;
  public response:any;
  public corevalue:any;

  constructor(private ActivatedRoute:ActivatedRoute, private MatSnackBar:MatSnackBar, private AboutusService:AboutusService, private Router:Router) { }

  ngOnInit() {
    this.coreId = this.ActivatedRoute.snapshot.paramMap.get('id');
    this.getDetails();
  }

  selectImage(event){
    this.corevalues.corevalues_image = event.target.files[0];

    // Below part is used to display the selected image
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event2:any) => {
      this.core_image = event2.target.result;
   };
  }

  updateCoreValue = () =>{
    const formData = new FormData();
    formData.append('corevalues_title', this.corevalues.corevalues_title);
    formData.append('corevalues_text', this.corevalues.corevalues_text);
    if(this.corevalues.corevalues_image != ""){
      formData.append('corevalues_image', this.corevalues.corevalues_image);
    }
    this.AboutusService.updateCoreVlues(this.coreId, formData).subscribe((data:any)=>{
      this.openSnackBar();
      this.Router.navigate(['/admin/view-core-values']);
    });
  }

  openSnackBar() {
    this.MatSnackBar.openFromComponent(SnackbarComponent, {
      duration: 500,
      data: 'Values Updated Successfully'
    });
  }

  getDetails = () =>{
    this.AboutusService.getSingleCoreValue(this.coreId).subscribe((data:any)=>{
      this.response = data;
      this.corevalue = this.response;
      this.corevalues.corevalues_title = this.corevalue.corevalues_title;
      this.corevalues.corevalues_text = this.corevalue.corevalues_text;
      this.core_image = this.corevalue.corevalues_image;
    })
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-viewmembers',
  templateUrl: './viewmembers.component.html',
  styleUrls: ['./viewmembers.component.css']
})
export class ViewmembersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { PromotionService } from 'src/app/service/promotion.service';
import { ContentService } from 'src/app/service/content.service';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-addcontent',
  templateUrl: './addcontent.component.html',
  styleUrls: ['../styles.css']
})
export class AddcontentComponent implements OnInit {
promotions : any;
cpromotion;
ccontent;
editorConfig: AngularEditorConfig = {
  editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '330px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
  uploadUrl: 'http://localhost:8081/api/contentImages/',
  sanitize: true,
  toolbarPosition: 'top',

};
  constructor(private promotion : PromotionService, private contentserv : ContentService, private router : Router) { }

  ngOnInit( ) {
    console.log(this.cpromotion);
    this.promotion.getPromotion().subscribe((res)=>{
      this.promotions = res;
    })
  }
  addContent(){
    const data = {
      promotion : this.cpromotion,
      content : this.ccontent
    }
    console.log(data);
    this.contentserv.postContent(data).subscribe((res)=>{
      console.log(res);
      this.router.navigate(['/admin/view-content']);
    })
  }
}

import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BannerService } from 'src/app/services/banner.service';
import { BlogService } from 'src/app/services/blog.service';
import { TeamService } from 'src/app/services/team.service';
import { ProductService } from 'src/app/services/product.service';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';

import * as $ from 'jquery';
import { SwiperConfigInterface, SwiperAutoplayInterface, SwiperControllerInterface } from 'ngx-swiper-wrapper';
import { PromotionService } from 'src/app/service/promotion.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../style.css']
})
export class HomeComponent implements OnInit {
response:any = [];
banners; any;
recentBlogs: any;
promotions: any;
ourTeams : any;
featuredProducts : any;
swipper : SwiperControllerInterface;
categories: any;
public config: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  observer: true,
  keyboard: true,
  autoplay: true,
  navigation: true,
  loop: true, // Autoplay option having value in milliseconds
      initialSlide: 4, // Slide Index Starting from 0
  // Slides Visible in Single View Default is 1
      pagination: true, // Pagination Class defined
};

loading : boolean = false;
// Product Finder Array
model_number : any = [];
display_size : any = [];
front_pane : any = [];
resolution : any = [];
dust_proof : any = [];
wall_mount : any = [];
visa_pattern : any = [];
connections : any = [];
speaker : any = [];
operating_systems : any = [];
storage : any = [];
tv_power : any = [];
remote_control_supplied : any = [];
tv_dimesions : any = [];
recess_wall_dimensions : any = [];
parking_includes : any = [];
special_features : any = [];
tunner : any = [];
mouse_pointer : any = [];
voice_control : any = [];
touch_keys : any = [];
touch_screen : any = [];
wifi : any = [];
brightness: any = [];
// End

// Product Finder Array
model_number_data : any = '';
display_size_data : any = '';
front_pane_data : any = '';
resolution_data : any = '';
dust_proof_data : any = '';
wall_mount_data : any = '';
visa_pattern_data : any = '';
connections_data : any = '';
speaker_data : any = '';
operating_systems_data : any = '';
storage_data : any = '';
tv_power_data : any = '';
remote_control_supplied_data : any = '';
tv_dimesions_data : any = '';
recess_wall_dimensions_data : any = '';
parking_includes_data : any = '';
special_features_data : any = '';
tunner_data : any = '';
mouse_pointer_data : any = '';
voice_control_data : any = '';
touch_keys_data : any = '';
touch_screen_data : any = '';
wifi_data : any = '';
brightnes_data: any = '';
category_data : any = ''; 

public config1: SwiperConfigInterface = {
  direction: 'horizontal',
  observer: true,
  keyboard: true,
  navigation: true,
  loop: true, // Autoplay option having value in milliseconds
  initialSlide: 4, // Slide Index Starting from 0
  slidesPerView: 1, // Slides Visible in Single View Default is 1
  pagination: true, // Pagination Class defined
};

  constructor(private bannerServices : BannerService , private BlogsSeervice : BlogService,
    private teamService : TeamService, private sanitizer: DomSanitizer, 
    private productService: ProductService , private router : Router , 
    private promoService : PromotionService, @Inject(DOCUMENT) private document: Document, private catServices:CategoryService) {

    }

  ngOnInit() {
    this.getAllProducts();
    this.catServices.getCategoriesLength().subscribe((res)=>{
      this.categories = res;
    })

    this.promoService.getPromotion().subscribe((res)=>{
      this.promotions  = res;
    })
    this.bannerServices.getBanner().subscribe((res)=>{
      this.banners = res;
    })
    this.BlogsSeervice.homepageBlogs().subscribe((res)=>{
      this.recentBlogs = res;
    })

    this.teamService.getTeam().subscribe((res)=>{
      this.ourTeams = res;
    });

    this.productService.getFeaturedProducts().subscribe((data:any)=>{
      let response  = data;
      this.featuredProducts = response;
      this.featuredProducts.forEach((element, index) => {
          console.log(element.productImages.imagePath[0]);
      });
    });
  }

  findProduct = () =>{
    this.loading = true;
    let data = {
      category :  this.category_data,
      model_number : this.model_number_data,
      display_size : this.display_size_data,
      front_pane : this.front_pane_data,
      resolution : this.resolution_data,
      dust_proof : this.dust_proof_data,
      wall_mount : this.wall_mount_data,
      visa_pattern : this.visa_pattern_data,
      connections : this.connections_data,
      speaker : this.speaker_data,
      operating_systems : this.operating_systems_data,
      storage : this.storage_data,
      tv_power : this.tv_power_data,
      remote_control_supplied : this.remote_control_supplied_data,
      tv_dimesions : this.tv_dimesions_data,
      recess_wall_dimensions : this.recess_wall_dimensions_data,
      parking_includes : this.parking_includes_data,
      special_features : this.special_features_data,
      tunner : this.tunner_data,
      mouse_pointer : this.mouse_pointer_data,
      voice_control : this.voice_control_data,
      touch_keys : this.touch_keys_data,
      touch_screen : this.touch_screen_data,
      wifi : this.wifi_data,
      brightness: this.brightnes_data,
    };

    let product_ids = [];
    let params = '';
    this.productService.productFinder(data).subscribe((success:any)=>{
      this.loading = false;
      if(success.status == 404){
        alert('Please select Something...');return;
      }
      this.response = success.data;
      if(this.response.length !== 0){
        this.response.map((data, index) => {
          product_ids.push(data._id);
        });
        params = product_ids.join('-');
      }
      this.router.navigate(['product-finder'], {queryParams:{params:params}});
    });
  }

  gotopro(product){
    this.router.navigate(['/products/', product.category.split(' ').join('-').toLowerCase()]) 
  }

  getAllProducts = () =>{
    let allproducts = [];
    let finder = [];

    this.productService.getLength().subscribe((success:any)=>{
      allproducts = success;

      // Getting data per column 
      allproducts.forEach((data, index) => {
        this.model_number.push(data.product_finder ? data.product_finder.model_number : '');
        this.display_size.push(data.product_finder ? data.product_finder.display_size : '')
        this.front_pane.push(data.product_finder ? data.product_finder.front_pane : '')
        this.resolution.push(data.product_finder ? data.product_finder.resolution : '')
        this.dust_proof.push(data.product_finder ? data.product_finder.dust_proof : '')
        this.wall_mount.push(data.product_finder ? data.product_finder.wall_mount : '')
        this.visa_pattern.push(data.product_finder ? data.product_finder.visa_pattern : '')
        this.connections.push(data.product_finder ? data.product_finder.connections : '')
        this.speaker.push(data.product_finder ? data.product_finder.speaker : '')
        this.operating_systems.push(data.product_finder ? data.product_finder.operating_systems : '')
        this.storage.push(data.product_finder ? data.product_finder.storage : '')
        this.tv_power.push(data.product_finder ? data.product_finder.tv_power : '')
        this.remote_control_supplied.push(data.product_finder ? data.product_finder.remote_control_supplied : '')
        this.tv_dimesions.push(data.product_finder ? data.product_finder.tv_dimesions : '')
        this.recess_wall_dimensions.push(data.product_finder ? data.product_finder.recess_wall_dimensions : '')
        this.parking_includes.push(data.product_finder ? data.product_finder.parking_includes : '')
        this.special_features.push(data.product_finder ? data.product_finder.special_features : '')
        this.tunner.push(data.product_finder ? data.product_finder.tunner : '')
        this.mouse_pointer.push(data.product_finder ? data.product_finder.mouse_pointer : '')
        this.voice_control.push(data.product_finder ? data.product_finder.voice_control : '')
        this. touch_keys.push(data.product_finder ? data.product_finder.touch_keys : '')
        this.touch_screen.push(data.product_finder ? data.product_finder.touch_screen : '')
        this.wifi.push(data.product_finder ? data.product_finder.wifi : '')
        this.brightness.push(data.product_finder ? data.product_finder.brightness : '')
      });

      // Getting distinct data;
      this.model_number = this.model_number.filter((c, index) => {
          return this.model_number.indexOf(c) === index && c != '';
      });

      this.display_size = this.display_size.filter((c, index) => {
          return this.display_size.indexOf(c) === index && c != '';
      });

      this.front_pane = this.front_pane.filter((c, index) => {
          return this.front_pane.indexOf(c) === index && c != '';
      });

      this.resolution = this.resolution.filter((c, index) => {
          return this.resolution.indexOf(c) === index && c != '';
      });

      this.dust_proof = this.dust_proof.filter((c, index) => {
          return this.dust_proof.indexOf(c) === index && c != '';
      });

      this.wall_mount = this.wall_mount.filter((c, index) => {
        return this.wall_mount.indexOf(c) === index && c != '';
      });

      this.visa_pattern = this.visa_pattern.filter((c, index) => {
          return this.visa_pattern.indexOf(c) === index && c != '';
      });

      this.connections = this.connections.filter((c, index) => {
          return this.connections.indexOf(c) === index && c != '';
      });

      this.speaker = this.speaker.filter((c, index) => {
          return this.speaker.indexOf(c) === index && c != '';
      });

      this.operating_systems = this.operating_systems.filter((c, index) => {
        return this.operating_systems.indexOf(c) === index && c != '';
      });

      this.storage = this.storage.filter((c, index) => {
          return this.storage.indexOf(c) === index && c != '';
      });

      this.tv_power = this.tv_power.filter((c, index) => {
          return this.tv_power.indexOf(c) === index && c != '';
      });

      this.remote_control_supplied = this.remote_control_supplied.filter((c, index) => {
          return this.remote_control_supplied.indexOf(c) === index && c != '';
      });

      this.tv_dimesions = this.tv_dimesions.filter((c, index) => {
        return this.tv_dimesions.indexOf(c) === index && c != '';
      });

      this.recess_wall_dimensions = this.recess_wall_dimensions.filter((c, index) => {
          return this.recess_wall_dimensions.indexOf(c) === index && c != '';
      });

      this.parking_includes = this.parking_includes.filter((c, index) => {
          return this.parking_includes.indexOf(c) === index && c != '';
      });

      this.special_features = this.special_features.filter((c, index) => {
          return this.special_features.indexOf(c) === index && c != '';
      });

      this.tunner = this.tunner.filter((c, index) => {
        return this.tunner.indexOf(c) === index && c != '';
      });

      this.mouse_pointer = this.mouse_pointer.filter((c, index) => {
          return this.mouse_pointer.indexOf(c) === index && c != '';
      });

      this.voice_control = this.voice_control.filter((c, index) => {
          return this.voice_control.indexOf(c) === index && c != '';
      });

      this.touch_keys = this.touch_keys.filter((c, index) => {
          return this.touch_keys.indexOf(c) === index && c != '';
      });

      this.touch_screen = this.touch_screen.filter((c, index) => {
        return this.touch_screen.indexOf(c) === index && c != '';
      });

      this.wifi = this.wifi.filter((c, index) => {
          return this.wifi.indexOf(c) === index && c != '';
      });

      this.brightness = this.brightness.filter((c, index) => {
          return this.brightness.indexOf(c) === index && c != '';
      });
    });
  }

}


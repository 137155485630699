import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { PageEvent } from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['../styles.css']
})
export class EnquiryComponent implements OnInit {
  enquiry :any;
  pageOptions = [5,10,15,20];
  totalProduct : any;
  prodcutPerPage = 10;
  currentpage = 1;
  holder;
  constructor(private service: ProductService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.service.getEnquiry(this.prodcutPerPage , this.currentpage).subscribe((res)=>{
      this.enquiry = res;
    })
    this.service.getEnquiryLength().subscribe((res)=>{
      this.holder = res;
      this.totalProduct = this.holder.length;
    });
  }

  refresh(){
    this.service.getEnquiry(this.prodcutPerPage , this.currentpage).subscribe((res)=>{
      this.enquiry = res;
    })
    this.service.getEnquiryLength().subscribe((res)=>{
      this.holder = res;
      this.totalProduct = this.holder.length;
    });
  }

  changePage(event :PageEvent){
    this.prodcutPerPage = event.pageSize
    this.currentpage = event.pageIndex + 1
    this.service.getEnquiry(this.prodcutPerPage , this.currentpage).subscribe((res)=>{
      this.enquiry = res;
    })
  }

  deleteEnquiry = (enquiry:any) =>{
    this.service.deleteEnquiry(enquiry._id).subscribe((data:any)=>{
        this.openSnackBar();
        this.refresh();
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 500,
      data : "Enquiry Removed..."
    });
  }

}

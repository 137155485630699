import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lcminfo',
  templateUrl: './lcminfo.component.html',
  styleUrls: ['../style.css']
})
export class LcminfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class VideoService {
  public url : string = environment.apiUrl + 'video';
  constructor(private http: HttpClient) {
   }
   getVideos(){
    return this.http.get(this.url);
    }
    getVideoDetail(id){
      return this.http.get(this.url+'/'+id);
    }
    postVideo(data){
    return this.http.post(this.url , data);
    }
    updateVideo(id, data){
    return this.http.put(this.url + '/' + id, data);
    }
    deleteVideo(id){
      return this.http.delete(this.url+'/'+ id);
    }
}

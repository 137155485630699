import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class PromotionService {
  public url : string = environment.apiUrl + 'promotion/'
  constructor(private http: HttpClient) { }
  getPromotion(){
    return this.http.get(this.url);
  }
  getPromotionDetails(id){
    return this.http.get(this.url+id);
  }
  postPromotions(title : string,  image:File|null , video : string|null , content: string){
    const data = new FormData();
     data.append('title', title);
     data.append('image', image);
     data.append('video', video );
     data.append('content', content);
     return this.http.post(this.url, data);
  }
editPromotionsimage(id: string, title : string, image:File| string , content: string ){
if(typeof image === 'object'){
  const data = new FormData();
  data.append('id', id);
  data.append('title', title);
  data.append('image', image);
 data.append('content', content);
  return this.http.put(this.url+id, data);
}
else{
  const data1 =  new FormData();
  data1.append('id', id);
  data1.append('title', title);
  data1.append('imagePath', image);
  data1.append('content', content);
  return this.http.put(this.url + id, data1);
}
}
editPromotionsvideo(id: string, title : string,  video : string , content: string){
  const data1 =  new FormData();
  data1.append('id', id);
  data1.append('title', title);
  data1.append('video', video); 
  data1.append('content', content);
  return this.http.put(this.url + id, data1);
}

deletePromotion(id){
return this.http.delete(this.url + id);
}

}



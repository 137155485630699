import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-productfinder',
  templateUrl: './productfinder.component.html',
  styleUrls: ['../style.css']
})
export class ProductfinderComponent implements OnInit {
  data : any;
  response : any = [];
  products : any = [];
  loading:any = false;
  show :boolean = false;
  listView : boolean = true;

  constructor(public dialog: MatDialog, private router : Router, private route : ActivatedRoute, private ProductService:ProductService) { }

  ngOnInit() {
    this.data = this.route.snapshot.queryParamMap.get("params");
    if(this.data != ''){
      this.data = this.data.split('-');
    }
    this.getProduct();
  }

  getProduct = () =>{
    this.loading = true;
    if(this.data != ''){
      this.ProductService.getProductFinder(this.data).subscribe((success:any)=>{
        this.loading = false;
        this.response = success;
        this.products = this.response.data;
          if(this.products.length == 0)
          {
            this.show = true;
          }
      });
    }else{
      this.loading = false;
      this.show = true;
    }
  }

  gotopro(product){
    this.router.navigate(['/product-deatils/', product._id]);
  }

  gridView = () =>{
    this.listView = !this.listView;
  }

  listingView = () =>{
    this.listView = !this.listView;
  }

  openDialog(product:any): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '400px',
      data: {product :  product}
    });
  }

}

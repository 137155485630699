import { Component, OnInit } from '@angular/core';
import { PromotionService } from 'src/app/service/promotion.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-editpromotion',
  templateUrl: './editpromotion.component.html',
  styleUrls: ['../styles.css']
})
export class EditpromotionComponent implements OnInit {
id: any;
ptitle: string ='';
promotionImage: any;
selectedFile: File = null;
holder;
imageUrl;
ccontent: string;
pvideo: string;
showVideo: boolean;
showImage: boolean;
editorConfig: AngularEditorConfig = {
  editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '330px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
  uploadUrl: 'https://soulaca.com/api/contentImages/',
  sanitize: true,
  toolbarPosition: 'top',

};
  constructor(private  promotionservice : PromotionService , private router : Router, private actroute : ActivatedRoute) { }

  ngOnInit() {
    this.id = this.actroute.snapshot.paramMap.get('id');
    this.promotionservice.getPromotionDetails(this.id).subscribe((res)=>{
    this.holder = res
    this.ptitle = this.holder.title;
    this.imageUrl = this.holder.imagePath
      this.pvideo = this.holder.videoPath
      this.ccontent = this.holder.content
      if(!this.imageUrl){
        this.showVideo = true;
      }
      else if(!this.pvideo){
        this.showImage = true;
      }
    })
  }
  selectImage(event : Event){
    const file = (event.target as HTMLInputElement).files[0];
    this.selectedFile =  (event.target as HTMLInputElement).files[0];
    let reader = new FileReader();
    reader.onload = () => {
      this.promotionImage = reader.result;
    }
    reader.readAsDataURL (file);
  }
  updatePromotion(){
    if(this.showImage){
      if(this.selectedFile !== null && this.selectedFile){
        this.promotionservice.editPromotionsimage(this.id, this.ptitle, this.selectedFile ,this.ccontent).subscribe((res)=>{
          console.log(res);
          this.router.navigate(['/admin/view-promotion']);
        })
      }
      else{
        this.promotionservice.editPromotionsimage(this.id, this.ptitle, this.imageUrl , this.ccontent).subscribe((res)=>{
          console.log(res);
          this.router.navigate(['/admin/view-promotion']);
        })
      }
    }
else if(this.showVideo){
  this.promotionservice.editPromotionsvideo(this.id, this.ptitle, this.pvideo , this.ccontent ).subscribe((res)=>{
    console.log(res);
    this.router.navigate(['/admin/view-promotion']);
  })
}
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AboutusService } from 'src/app/services/aboutus.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-editaboutus',
  templateUrl: './editaboutus.component.html',
  styleUrls: ['../styles.css']
})
export class EditaboutusComponent implements OnInit {
  response:any;
  abtid : any;
  atitle:string ='';
  acontent:string = '';
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '330px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
    uploadUrl: 'https://soulaca.com/api/contentImages/',
    sanitize: true,
    toolbarPosition: 'top',
  
  };
  editorConfig_2: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '330px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: false,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
    uploadUrl: 'https://soulaca.com/api/contentImages/',
    sanitize: true,
    toolbarPosition: 'top',
  
  };
  
  about_type : any = "";
  left_title : any;
  left_content_type:any = "";
  left_content_image:any;
  left_content_text:any;
  
  right_title : any;
  right_content_type:any = "";
  right_content_image:any;
  right_content_text:any;
  selectedFile : any;
  image_1:any;
  image_2:any;
  
    constructor(private ActivatedRoute:ActivatedRoute, private router: Router, private about_service: AboutusService) { }
  
    ngOnInit() {
      this.abtid = this.ActivatedRoute.snapshot.paramMap.get('id');
      this.about_service.getAboutDetail(this.abtid).subscribe((data:any)=>{
        this.response = data;
        console.log(this.response);
        this.about_type  = this.response.about_type;
        this.left_title = this.response.left_title;
        this.left_content_type = this.response.left_content_type;
        this.image_1 = this.response.left_content_image;
        this.left_content_text = this.response.left_content_text;
        
        this.right_title = this.response.right_title;
        this.right_content_type = this.response.right_content_type;
        this.image_2 = this.response.right_content_image;
        this.right_content_text = this.response.right_content_text;
      });
    }
    updateAbout(){
    
      const formData = new FormData();
      formData.append('about_type', this.about_type);
      formData.append('left_title', this.left_title);
      formData.append('left_content_type', this.left_content_type);
      formData.append('image', this.left_content_image);
      formData.append('left_content_text', this.left_content_text);
      formData.append('right_title', this.right_title);
      formData.append('right_content_type', this.right_content_type);
      formData.append('image', this.right_content_image);
      formData.append('right_content_text', this.right_content_text);
      
      this.about_service.updateAbout(this.abtid, formData).subscribe((res)=>{
        this.router.navigate(['/admin/view-aboutus-contents']);
      },
      (err)=>{
        console.log(err);
      })
    }
  
    onLeftFileSelected(event: any): void {
      this.left_content_image = event.target.files[0];
  
      // Below part is used to display the selected image
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event2:any) => {
        this.image_1 = event2.target.result;
     };
    }
  
    onRightFileSelected(event: any): void {
      this.right_content_image = event.target.files[0];
  
      // Below part is used to display the selected image
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event2:any) => {
        this.image_2 = event2.target.result;
     };
    }
}

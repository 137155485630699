import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ImagesService {
  public url : string =  environment.apiUrl + 'images';
  constructor(private http: HttpClient) { }

  getImages(pageSize:number , currentPage:number){
    const queryParams = `?pageSize=${pageSize}&currentPage=${currentPage}`;
    return this.http.get(this.url);
  }

  postImages(name : string, image:File | string){
    const data = new FormData();
    data.append ("name" , name);
    data.append("image", image);
    return this.http.post(this.url , data);
  }
  deleteImages(id:any){
   return this.http.delete(this.url+'/'+id);
  }


}
